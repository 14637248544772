import { Colors, Fonts, FontSizes, FontWeights } from './types'

/**
 * Colors
 */
export const colors: Colors = {
  black: `#000000`,
  contrast: `#ffffff`,
  grey: `#b6b6b6`,
  lightGrey: `#ecebf1`,
  primary: `#201430`,
  secondary: `#d5d5d5`,
  text: `#3C404B`,
}

export const fonts: Fonts = {
  primary: `'Work Sans', sans-serif`,
}

export const fontSizes: FontSizes = {
  root: 16,
  h1: 48,
}

export const fontWeights: FontWeights = {
  medium: 500,
  regular: 400,
}

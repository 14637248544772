import React, { ReactElement, useCallback, useEffect } from 'react'
import { Helmet } from 'react-helmet'

/**
 * Get static dataLayer used by Google
 */
export function getDataLayer(): any {
  // eslint-disable-next-line no-return-assign
  return ((window as any).dataLayer = (window as any).dataLayer || [])
}

/**
 * Google ads ID
 */
export function getGoogleAdsId(): string {
  return process.env.GATSBY_GOOGLE_ADS_ID || ``
}

/**
 * Returns a function to push to dataLayer
 */
export function useGtag() {
  // Note: Do not remove eslint-disable lines as there's no other way to solve this
  // unless we use typings from Google, which is not too critical for us to use
  // eslint-disable-next-line func-names, @typescript-eslint/no-unused-vars
  return useCallback(function (...args: any[]) {
    const dataLayer = getDataLayer()
    // eslint-disable-next-line prefer-rest-params
    dataLayer.push(arguments)
  }, [])
}

/**
 * Google Ads component
 */
export default function GoogleAds(): ReactElement {
  const googleAdsId = getGoogleAdsId()
  const gtag = useGtag()

  useEffect(() => {
    if (googleAdsId) {
      gtag(`js`, new Date())
      gtag(`config`, googleAdsId)
    }
  }, [googleAdsId, gtag])

  return (
    <Helmet>
      {!!googleAdsId && (
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${googleAdsId}`}
        />
      )}
    </Helmet>
  )
}

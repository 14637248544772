import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Container as GridContainer } from '../../styles/grid'
import { mobile, rem } from '../../styles/functions'
import { colors, fontWeights } from '../../styles/variables'

export const Wrapper = styled.section`
  background-color: ${colors.grey};
  padding-bottom: 24px;
  color: ${colors.secondary};

  ${mobile(css`
    padding-bottom: 20px;
  `)}
`

export const Container = styled(GridContainer)`
  flex-direction: row;
`

export const MenuWrapper = styled.ul`
  display: block;
  text-align: center;
  list-style-type: none;
  /* margin: 56px 0 30px 0; */
  padding: 0;
  flex-grow: 1;

  li {
    display: inline-block;
    margin: 0 22px;
  }

  a {
    color: ${colors.text};
    text-decoration: none;
    text-transform: uppercase;
    font-size: ${rem(13)};
    font-weight: ${fontWeights.medium};
  }

  ${mobile(css`
    margin-top: 32px;
  `)}
`

export const Copyright = styled.div`
  display: block;
  font-size: ${rem(12)};
  font-weight: ${fontWeights.medium};
  text-align: right;
  color: ${colors.secondary};
  padding-top: 24px;

  ${mobile(css`
    max-width: 150px;
    margin-right: 20px;
  `)}
`

export const Logo = styled.div`
  display: block;
  width: 110px;
  margin: 24px 0 0 0;

  img {
    max-width: 100%;
  }

  ${mobile(css`
    max-width: 150px;
    margin-left: 20px;
  `)}
`

import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Container as GridContainer } from '../../styles/grid'
import { mobile, rem } from '../../styles/functions'
import { colors, fontWeights } from '../../styles/variables'

export const Wrapper = styled.section`
  padding-bottom: 30px;
  background-color: ${colors.grey};
  color: ${colors.mediumGrey};

  ${mobile(css`
    padding: 0;
  `)}
`

export const Container = styled(GridContainer)`
  padding-top: 30px;
  flex-direction: column;

  ${mobile(css`
    padding: 20px 20px 30px 20px;
  `)}
`

export const MenuWrapper = styled.ul`
  display: inline-block;
  list-style-type: none;
  margin: 0;
  padding: 0;
  flex-grow: 1;
  text-align: right;

  li {
    display: inline-block;
    margin-left: 48px;
  }

  a {
    text-decoration: none;
    font-size: ${rem(13)};
    font-weight: ${fontWeights.medium};
    color: inherit;
  }

  ${mobile(css`
    width: 100%;
    margin-top: 0;
    order: 1;
    text-align: left;
    margin-bottom: 20px;

    li {
      margin: 0 20px 0 0;
    }
  `)}
`

export const Bottom = styled(GridContainer)`
  width: 100%;

  > div {
    display: block;
    flex-grow: 1;
  }
`

export const Logo = styled.div`
  display: inline-block;
  width: 132px;
  float: left;

  img {
    max-width: 100%;
  }

  ${mobile(css`
    max-width: 120px;
  `)}
`

export const Copyright = styled.div`
  display: inline-block;
  font-size: ${rem(14)};
  font-weight: ${fontWeights.medium};
  color: inherit;

  ${mobile(css`
    width: 100%;
    order: 2;
  `)}
`

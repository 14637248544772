import React, { ReactElement } from 'react'
import { Helmet } from 'react-helmet'

export default function Pixel(): ReactElement {
  const url = typeof window !== `undefined` ? window.location.href : ``
  let clarityPixelId = ``
  if (url.includes(`socialsearchtoday.com`)) {
    clarityPixelId = `mmpq3bhqim`
  } else if (url.includes(`sociallysearching.com`)) {
    clarityPixelId = `mmprc1btr9`
  } else if (url.includes(`socialsearched.com`)) {
    clarityPixelId = `mmpr0h0mb1`
  } else if (url.includes(`socialsearchhelp.com`)) {
    clarityPixelId = `mmpp7dgtsi`
  } else if (url.includes(`socialsearchit.com`)) {
    clarityPixelId = `mmpqpheu2d`
  } else if (url.includes(`socialsearchlive.com`)) {
    clarityPixelId = `mmphwum6ga`
  } else if (url.includes(`socialsearchresults.com`)) {
    clarityPixelId = `mmpqfreegj`
  } else {
    return null
  }
  return (
    <>
      <Helmet>
        <script type="text/javascript">
          {`(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "${clarityPixelId}");`}
        </script>
      </Helmet>
    </>
  )
}

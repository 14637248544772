import React, { ReactElement, useEffect, useState } from 'react'

import Article from './Article'
import { ArticlesWrapper, LoadMore, NoArticles } from './styles'
import { ArticlesProps } from './types'

const initial = 4
const next = 4

/**
 * Articles component
 */
export default function Articles({ articles }: ArticlesProps): ReactElement {
  const [limit, setLimit] = useState<number>(initial)

  const handleLoadMore = () => {
    setLimit((prev) => prev + next)
  }

  useEffect(() => {
    setLimit(initial)
  }, [articles])

  return (
    <ArticlesWrapper>
      {!articles.length && <NoArticles>No article(s) found</NoArticles>}
      {articles.slice(0, limit).map((article, index) => (
        <Article
          key={article.id}
          article={article}
          no={index + 1}
          type="search"
        />
      ))}
      {limit < articles.length && (
        <LoadMore>
          <button type="button" onClick={handleLoadMore}>
            Load More
          </button>
        </LoadMore>
      )}
    </ArticlesWrapper>
  )
}

import React, { ReactElement } from 'react'
import { Helmet } from 'react-helmet'

import Articles from '../../components/article/Articles'
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs'
import { Container, Wrapper } from './styles'
import { LinkInterface } from '@/types/common'
import { CategoryPageProps } from '@/types/pages/category'
import getCategoryUrl from '@/utils/routes/get-category-url'

/**
 * Category page
 */
export default function Category({
  pageContext,
}: CategoryPageProps): ReactElement {
  const { articles, category } = pageContext
  const breadcrumbs: LinkInterface[] = [
    {
      title: `Home`,
      url: `/`,
    },
    {
      title: category.label,
      url: getCategoryUrl(category),
    },
  ]
  return (
    <Wrapper>
      <Helmet>
        <title>{`${pageContext.siteSettings.title} - ${category.label}`}</title>
      </Helmet>
      <Breadcrumbs links={breadcrumbs} />
      <Container>
        <Articles articles={articles} />
      </Container>
    </Wrapper>
  )
}

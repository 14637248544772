import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { mobile } from '../../styles/functions'

export const Wrapper = styled.section``

export const Container = styled.div`
  display: block;
  margin: 0 auto;
  max-width: 1160px;

  ${mobile(css`
    padding: 20px;
  `)}
`

export const FeaturedSection = styled.div`
  display: block;

  .general {
    width: 65%;
    float: right;
    margin: 30px 0 30px 30px;
  }

  .art-image {
    height: 400px;
  }

  ${mobile(css`
    padding: 20px;
  `)}
`

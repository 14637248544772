import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Container as GridContainer } from '../../styles/grid'
import { mobile, rem } from '../../styles/functions'
import { colors, fontWeights } from '../../styles/variables'

export const Wrapper = styled.section`
  padding-bottom: 30px;
  background-color: #2f455c;

  ${mobile(css`
    padding-bottom: 20px;
  `)}
`

export const Container = styled(GridContainer)`
  max-width: 1160px;
`

export const MenuWrapper = styled.ul`
  display: inline-block;
  list-style-type: none;
  margin: 26px 40px 0 120px;
  padding: 0;
  flex-grow: 1;
  text-align: right;

  li {
    display: inline-block;
    margin: 0;
    margin-right: 45px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  a {
    color: ${colors.contrast};
    text-decoration: none;
    font-size: ${rem(17)};
    font-weight: ${fontWeights.medium};

    position: relative;
    padding: 0.3em 0;

    &.is-active {
      color: ${colors.contrast};
    }
  }

  ${mobile(css`
    margin-top: 32px;
  `)}
`

export const Bottom = styled(GridContainer)`
  color: ${colors.mediumGrey};
  width: 100%;

  > div {
    display: block;
    flex-grow: 1;
  }
`

export const Logo = styled.div`
  display: inline-block;
  width: 190px;
  margin-top: 25px;

  img {
    max-width: 100%;
  }

  ${mobile(css`
    max-width: 120px;
  `)}
`

export const Copyright = styled.div`
  display: inline-block;
  color: ${colors.contrast};
  font-size: ${rem(14)};
  font-weight: ${fontWeights.medium};
  float: right;
`

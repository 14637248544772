import createContextSet from '@/utils/create-context-set'
import reducer from './reducer'
import { AppAction, AppState } from './types'

export const defaultState: AppState = {
  articles: {},
  menu: [],
  siteSettings: {},
  isHome: true,
}

export const { Context, Provider, useContext } = createContextSet<
  AppAction,
  AppState
>(reducer, defaultState)

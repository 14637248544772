import { Article } from '@/types/models/article'

export type ARTICLE_FILTER = 'featured' | 'trending'

/**
 * Filter articles
 */
export default function getRandomArticles(
  articles: Article[],
  filter?: ARTICLE_FILTER,
  limit = 10,
): Article[] {
  /* if (!filter) {
    return articles
  } */
  const filtered = articles.filter((article) => {
    switch (filter) {
      case `featured`:
        return !!article.is_featured
      case `trending`:
        return !!article.is_trending
      default:
        return true
    }
  })
  return filtered.sort(() => 0.5 - Math.random()).slice(0, limit)
}

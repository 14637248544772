import { fontSizes } from './variables'

export { mobile } from '@/utils/styles'

/**
 * Rem
 */
export function rem(value: number): string {
  return `${(value / fontSizes.root).toFixed(6)}rem`
}

import styled from '@emotion/styled'

/**
 * Grid components
 */
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 900px;
  margin: 0 auto;
`

export const PageWrapper = styled.section`
  position: relative;
  z-index: 0;
`

import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Container as GridContainer } from '../../styles/grid'
import { mobile, rem } from '../../styles/functions'
import { colors, fontWeights } from '../../styles/variables'

export const Wrapper = styled.section`
  width: 100%;
  max-width: 1300px;
  padding: 0 20px 25px;
  margin: 0 auto;
  box-sizing: border-box;

  ${mobile(css`
    padding: 0 12px 5px 12px;
    box-sizing: border-box;
  `)}
`

export const Container = styled.div`
  padding: 25px 0 0;
  display: table;
  width: 100%;
  border-top: 1px solid #f0f0f0;

  > * {
    display: table-cell;
    vertical-align: top;
  }

  ${mobile(css`
    border-top: none;
    padding: 5px 0 0;
  `)}
`

export const MenuWrapper = styled.div`
  width: 540px;
  padding: 10px 0 0;
  text-align: right;

  a {
    font-size: 13px;
    margin-left: 15px;
    text-decoration: none;
    white-space: nowrap;
    box-sizing: border-box;
  }

  ${mobile(css`
    padding: 4px 0 0 15px;
    width: auto;
    text-align: left;

    a {
      margin-left: 0;
      margin-right: 15px;
      display: inline-table;
    }
  `)}
`

export const Logo = styled.div`
  width: 40px;

  a {
    display: block;

    img {
      width: 220px;
    }
  }

  ${mobile(css`
    a {
      width: 35px;
      height: 50px;
      overflow: hidden;

      img {
        display: inline-block;
        margin: 10px;
      }
    }
  `)}
`

export const Bottom = styled(GridContainer)`
  color: ${colors.mediumGrey};
  width: 100%;

  > div {
    display: block;
    flex-grow: 1;
  }
`

export const Copyright = styled.div`
  display: inline-block;
  color: ${colors.contrast};
  font-size: ${rem(14)};
  font-weight: ${fontWeights.medium};
  float: right;
`

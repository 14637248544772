import React, { FormEventHandler, ReactElement, useState } from 'react'
import { Link, navigate } from 'gatsby'
import { useContext } from '@/components/app/context'
import { Wrapper, Container } from './styles'

/**
 * Header
 */
export default function Header(): ReactElement {
  const [state] = useContext()
  const [search, setSearch] = useState<string>(``)

  const onSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault()
    event.stopPropagation()
    navigate(`/search/top5/?s=${encodeURIComponent(search)}`)
    setSearch(``)
  }

  return (
    <Wrapper>
      <Container>
        <div className="header-logo">
          <Link to="/">
            {!!state.siteSettings?.logo && (
              <img src={state.siteSettings.logo} alt="Site logo" />
            )}
          </Link>
        </div>
        <div className="header-form">
          <form action="/search" onSubmit={onSubmit}>
            <input
              type="search"
              onChange={(event) => setSearch(event.target.value)}
            />
            <button type="submit">
              <i className="fa fa-search" />
            </button>
          </form>
        </div>
      </Container>
    </Wrapper>
  )
}

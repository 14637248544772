import axios, { AxiosInstance } from 'axios'

let client: AxiosInstance

/**
 * Ads API client
 */
export default function getClient(): AxiosInstance {
  if (client) {
    return client
  }
  client = axios.create({
    baseURL: process.env.GATSBY_API_ROOT || ``,
    headers: {
      'Content-Type': `application/json`,
    },
    params: {
      token: process.env.GATSBY_API_TOKEN,
    },
  })
  return client
}

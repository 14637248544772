import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { mobile } from '../../styles/functions'

export const AdContainer = styled.div`
  .units {
    list-style-type: none;
    padding: 0;
  }

  .unit {
    background: #f8f8f8;
    margin: 0.5em 0 1.25em 0;
    padding: 0.5rem;
    border-radius: 5px;
    position: relative;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
  }

  .col-lg-10,
  .col-lg-2 {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }

  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .title {
    margin-bottom: 0;
  }

  h5 {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.2;
    margin-top: 0;
  }

  .host {
    margin-bottom: 0;
    line-height: 30px;
  }

  .title a {
    word-break: break-word;
    color: #212529;
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .description a,
  .host a,
  .title a {
    word-break: break-word;
    color: #212529;
  }

  .description {
    font-size: 0.875rem;
  }

  .cta {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 7px 0;
  }

  .cta_button {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding: 8px 0;
    width: 100%;
    border-radius: 5px;
    letter-spacing: 0.075rem;
    font-weight: 700;
    background: #f7a518;

    &:hover {
      background-color: #0274b2 !important;
      color: #000;
      text-decoration: underline;
      border: 1px solid #000;
    }
  }

  .cta_text {
    margin: 0;
  }

  .sponsored_text {
    color: #9b9b9b;
    font-size: 0.6875rem;
    margin-bottom: -10px !important;
  }

  ${mobile(css`
    .col-lg-10,
    .col-lg-2 {
      flex: auto;
      max-width: 100%;
    }
  `)}
`

import React, { ReactElement } from 'react'
import { Helmet } from 'react-helmet'
import clsx from 'clsx'

import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs'
import Contents from '@/components/contents/Contents'
import { Container, Content, Title, Wrapper } from './styles'
import { ArticlePageProps } from '@/types/pages/article'
import { LinkInterface } from '@/types/common'
import getArticleUrl from '@/utils/routes/get-article-url'

/**
 * Article page
 */
export default function Article({
  pageContext,
}: ArticlePageProps): ReactElement {
  const { article, id } = pageContext
  const breadcrumbs: LinkInterface[] = [
    {
      title: `Home`,
      url: `/`,
    },
    {
      title: article.title,
      url: getArticleUrl(article),
    },
  ]
  const images = article.images || []
  const image = images.find((item) => item.is_featured) || images[0]

  return (
    <Wrapper className={clsx({ 'is-live': !!article.is_live })}>
      <Helmet>
        <title>{`${pageContext.siteSettings.title} - ${article.title}`}</title>
      </Helmet>
      <Breadcrumbs hide links={breadcrumbs} />
      <Contents article={article} contentId={id}>
        <Container>
          <Title>{article.title}</Title>
          <Content>
            {!!image && <img src={image.path} alt={article.title} />}
            <div
              dangerouslySetInnerHTML={{ __html: article.content }}
              className="content"
            />
          </Content>
        </Container>
      </Contents>
    </Wrapper>
  )
}

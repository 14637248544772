import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Link } from 'gatsby'
import { mobile, rem } from '../../styles/functions'
import { colors, fontWeights } from '../../styles/variables'

export const ArticlesWrapper = styled.div`
  padding: 100px 0;

  ${mobile(css`
    padding: 20px 0;
  `)}
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 100px;

  &:last-of-type {
    margin-bottom: 0;
  }

  ${mobile(css`
    flex-direction: column;
    margin-bottom: 40px;
  `)}
`

export const Image = styled.div`
  display: block;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 42px;
  min-width: 360px;
  width: 360px;

  a {
    display: block;
    background-color: ${colors.lightGrey};
    height: 240px;
    position: relative;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  ${mobile(css`
    min-width: auto;
    width: 100%;
    margin: 0 0 20px 0;
    height: 200px;
  `)}
`

export const Content = styled.div`
  a {
    color: inherit;
    text-decoration: none;
  }

  h2 {
    font-size: ${rem(36)};
    font-weight: ${fontWeights.regular};
    letter-spacing: -2px;
    margin: -8px 0 0 0;
    color: ${colors.primary};
    line-height: ${rem(43)};
    margin-bottom: 14px;
  }
`

export const Meta = styled.div`
  margin-bottom: 24px;

  span {
    display: inline-block;
    border-right: 1px ${colors.grey} solid;
    padding-right: 10px;
    color: ${colors.grey};
    font-style: italic;
    font-weight: ${fontWeights.medium};
    font-size: ${rem(14)};
    letter-spacing: -1px;

    &:last-of-type {
      padding-right: 0;
      border-right: none;
    }
  }
`

export const Excerpt = styled.div`
  font-size: ${rem(16)};
  line-height: ${rem(30)};
  margin-bottom: 16px;

  ${mobile(css`
    line-height: ${rem(20)};
  `)}
`

export const ReadMore = styled(Link)`
  span {
    color: ${colors.secondary};
    font-weight: ${fontWeights.medium};
    font-size: ${rem(13)};
    text-transform: uppercase;
    text-decoration: underline;
  }
`

export const LoadMore = styled.div`
  display: block;
  text-align: center;

  button {
    background-color: ${colors.primary};
    border: none;
    border-radius: 2px;
    color: ${colors.contrast};
    outline: none;
    padding: 16px 36px;
    text-transform: uppercase;
    font-size: ${rem(12)};
    font-weight: ${fontWeights.medium};
    cursor: pointer;
  }
`

export const NoArticles = styled.div`
  font-size: 20px;
`

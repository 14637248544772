import React, { ReactElement, useMemo, useState } from 'react'

import { Wrapper } from './styles'
import { AIArticleProps } from './types'
import { shortenText } from '@/utils/shorten-text'

export default function AIArticleItem({
  aiArticle,
}: AIArticleProps): ReactElement {
  const [isTruncated, setIsTruncated] = useState(
    aiArticle.content.length > 1500,
  )

  const text = useMemo(
    () =>
      isTruncated ? shortenText(aiArticle.content, 1500) : aiArticle.content,
    [isTruncated, aiArticle],
  )

  const handleClickShowMore = () => {
    setIsTruncated(false)
  }

  return (
    <Wrapper>
      <div className="post-descr">
        <div dangerouslySetInnerHTML={{ __html: text }} className="content" />
      </div>
      {isTruncated && (
        <button
          type="button"
          onClick={handleClickShowMore}
          style={{
            cursor: `pointer`,
            marginTop: `1rem`,
            display: `block`,
            border: 0,
            padding: 0,
            backgroundColor: `transparent`,
          }}
        >
          Click Here to Read More of the Article {`>`}
        </button>
      )}
    </Wrapper>
  )
}

import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { mobile } from '../../styles/functions'
import { colors } from '../../styles/variables'

export const Wrapper = styled.section``

export const Container = styled.div`
  display: block;
  margin: 0 auto;
  max-width: 1300px;
  padding: 0 20px;

  ${mobile(css`
    padding: 0 12px;
  `)}
`

export const SearchSection = styled.div`
  padding: 0 0 10px;

  .li-label {
    font-size: 13px;
    color: #666;
    font-weight: 400;
    text-align: right;
    padding: 11px 0 2px;
  }
`

export const Loader = styled.div`
  display: block;
  margin: 10px;
`

export const Item = styled.div`
  display: table;
  width: 100%;
  background: #fff;
  border: 1px solid #66a8ec;
  margin-top: 11px;
  margin-bottom: 15px;

  > * {
    display: table-cell;
    vertical-align: top;
  }

  &:hover {
    background-color: #feede9;
  }

  .li-main {
    width: auto;
    line-height: 1.1;
    padding: 13px 25px 12px 17px;
  }

  .li-chevron {
    width: 140px;
    padding: 20px 30px 15px 0;
  }

  .li-chevron a {
    font-size: 15px;
    color: #fff;
    font-weight: 600;
    display: block;
    background: #ff562d;
    position: relative;
    height: 39px;
    border-radius: 20px;
    overflow: hidden;
    padding: 10px 0 0 21px;
  }

  .li-chevron a span:after {
    position: absolute;
    content: '>';

    right: 9px;
    top: 2px;
    font-size: 27px;
    font-weight: 400;
  }

  a {
    display: inline-block;
    width: 100%;
    color: inherit;
    text-decoration: none;
    box-sizing: border-box;
    position: relative;
    transition: 0.2s background-color ease;

    &:after {
      content: '›';
      position: absolute;
      right: 20px;
      top: 24px;
      font-size: 48px;
      padding: 8px 14px 16px 14px;
      border: 3px rgba(221, 56, 116, 1) solid;
      border-radius: 8px;
      line-height: 20px;
      color: rgba(221, 56, 116, 1);
      display: none;
    }

    .ad-title {
      color: #e80037;
      font-size: 17px;
      font-weight: 700;
      text-decoration: underline;
      line-height: 17px;
    }
  }

  .ad-description {
    font-size: 14px;
    font-weight: normal;
    padding-top: 5px;
    cursor: pointer;
    font-family: arial, sans-serif;
  }

  .ad-link {
    color: #3ca3fa;
    font-size: 13px;
  }

  a:after {
    content: none !important;
  }

  ${mobile(css`
    .li-main {
      padding: 8px 10px 8px 12px;
    }

    .li-chevron {
      width: 65px;
      padding: 12px 10px 10px 0;
    }

    .li-chevron a {
      height: 55px;
      border-radius: 50px;
      font-size: 12px;
      font-weight: 700;
      padding: 30px 0 0;
      line-height: 1;
      text-align: center;
    }

    .li-chevron a span:after {
      display: block;
      content: '>';
      right: 20px;
      top: 0;
      font-size: 28px;
    }
  `)}
`

export const FeaturedSection = styled.div`
  float: right;
  margin-top: 20px;
`

export const SearchInput = styled.div`
  display: flex;
  flex-direction: column;

  input {
    flex-grow: 1;
    display: inline-block;
    outline: none;
    padding: 12px 30px 12px 16px;
    border-radius: 4px;
    border: 1px ${colors.primary} solid;
    font-size: 24px;
  }

  div {
    font-size: 28px;
    line-height: 33px;
    margin-bottom: 20px;
    color: #01c08e;
    margin-top: 20px;
  }

  span {
    color: #464646;
  }
`

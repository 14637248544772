import { css, SerializedStyles } from '@emotion/react'

/**
 * Mobile
 */
export function mobile(content: SerializedStyles | string): SerializedStyles {
  return css`
    @media (max-width: 767.99px) {
      ${content}
    }
  `
}

import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { mobile } from '../../styles/functions'

export const Wrapper = styled.section`
  height: 65px;
  position: relative;
  z-index: 3;
  background-image: linear-gradient(to right, #F8F8F8, #F8AB27);
  width: 100%;

  ${mobile(css`
    height: 50px;
  `)}
}
`

export const Container = styled.div`
  width: 100%;
  max-width: 1300px;
  padding: 0 20px;
  margin: 0 auto;
  display: table;

  > * {
    display: table-cell;
    vertical-align: top;
  }

  .header-logo {
    width: auto;

    a {
      margin: 0;
      display: block;

      img {
        width: 220px;
        margin-top: 20px;
      }
    }
  }

  .header-form {
    width: 350px;
    padding: 11px 0 0;

    .no-border {
      border: none;
    }

    form {
      transition-delay: 10ms;
      display: block;
      position: relative;
      border: 1px solid #d9d9d9;
      border-radius: 1.5rem;

      input {
        display: block;
        height: 40px;
        border: none;

        width: 100%;
        font-size: 15px;
        color: #333;
        background: #f3f3f3;
        padding: 10px 35px 10px 20px;
        -webkit-appearance: none;
        border-radius: 1.5rem;

        :focus {
          outline: none;
        }
      }

      button {
        display: block;
        height: 40px;
        border: none;
        background: none;

        position: absolute;
        right: 0;
        top: 0;
        width: 40px;
        cursor: pointer;
      }

      button:before {
        color: #666;
        font-family: icomoon;
        color: #fff;
        font-size: 25px;
        font-weight: 700;
      }
    }
  }

  ${mobile(css`
    padding: 0 12px;
    box-sizing: border-box;

    .header-logo {
      padding-left: 0;
      padding-right: 10px;

      a {
        height: 50px;
        overflow: hidden;

        img {
          display: inline-block;
          margin: 14px 10px;
          width: 180px;
        }
      }
    }

    .header-form {
      padding: 4px 0 0;
      width: auto;
    }
  `)}
`

import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { mobile } from '../../styles/functions'

export const Wrapper = styled.section`
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid #f0f0f0;

  ${mobile(css`
    padding: 0 12px 5px 12px;
    box-sizing: border-box;
  `)}
`

export const Container = styled.div`
  padding: 25px 20px 25px 25px;
  display: table;
  width: 100%;

  max-width: 1300px;
  margin: 0 auto;

  > * {
    display: table-cell;
    vertical-align: top;
  }

  .footer-logo {
    width: 40px;

    a {
      display: block;

      img {
        width: 220px;
      }
    }
  }

  .footer-menu {
    padding: 10px 0 0;
    text-align: right;

    a {
      font-size: 13px;
      margin-left: 15px;
      text-decoration: none;
      white-space: nowrap;
      box-sizing: border-box;
    }
  }

  .nav-menu {
    display: none;

    button {
      border: none;
      background: transparent;
    }
  }

  ${mobile(css`
    padding: 5px 0 0;

    .nav-menu {
      padding: 10px 16px;
      font-size: 17px;
      display: block;
      text-align: right;
    }

    .footer-logo {
      a {
        height: 50px;
        overflow: hidden;

        img {
          display: inline-block;
          margin: 10px;
          width: 180px;
        }
      }
    }

    .footer-menu {
      padding: 4px 0 0 15px;
      width: 100%;
      text-align: left;
      display: none;
      position: fixed;
      bottom: 60px;
      right: 0px;
      background-color: #d3d3d3;

      a {
        padding: 10px;
        margin: 0px;
        display: block;
      }
    }
  `)}
`

export const Logo = styled.div``

export const MenuWrapper = styled.div`
  padding: 10px 0 0;
  text-align: right;

  a {
    font-size: 13px;
    margin-left: 15px;
    text-decoration: none;
    white-space: nowrap;
    box-sizing: border-box;
  }

  ${mobile(css`
    padding: 4px 0 0 15px;
    width: 100%;
    text-align: left;
    display: none;
    position: fixed;
    bottom: 60px;
    right: 0px;
    background-color: #d3d3d3;

    a {
      padding: 10px;
      margin: 0px;
      display: block;
    }
  `)}
`

import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile } from '../../styles/functions'

/**
 * App styles
 */
export const Wrapper = styled.div`
  display: block;
  width: 100%;

  ${mobile(css`
    width: 100%;
  `)}
`

export const Content = styled.section`
  flex-grow: 1;
`

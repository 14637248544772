import isUndefined from 'lodash/isUndefined'
import { stripHtml } from 'string-strip-html'

/**
 * HTML to excerpt
 */
export default function htmlToExcerpt(
  html: string,
  limit?: number,
  suffix = `...`,
): string {
  const stripped = stripHtml(html).result.trim()
  const exceeds = stripped.length > limit
  const trimmed = isUndefined(limit)
    ? stripped
    : stripped.substring(0, limit).trim()
  return `${trimmed.replaceAll(`\n`, `<br /><br />`)}${exceeds ? suffix : ``}`
}

import React, { ReactElement } from 'react'
import { GatsbyBrowser } from 'gatsby'
import { Helmet } from 'react-helmet'

import { useLocation } from '@reach/router'
import useClient from '@/utils/hooks/use-client'
import { PageProps } from './types'
import { useContext } from '@/components/app/context'

/**
 * Page component
 */
export function Page({ children }: PageProps): ReactElement {
  const isClient = useClient()
  const [, hostname = ``] = isClient
    ? (window.location.hostname || ``).split(`.`)
    : []

  const location = useLocation()
  const [state] = useContext()

  if (location.pathname === `/` || location.pathname === ``) {
    state.isHome = true
  } else {
    state.isHome = false
  }

  return (
    <>
      <Helmet>
        {hostname === `smarter` && (
          <script
            async
            src="https://cdn.avantisvideo.com/avm/js/video-loader.js?id=fecaff7b-ce7b-46dd-9104-5843e7b31318&tagId=4"
            id="avantisJS"
          />
        )}
        {hostname === `faqtoids` && (
          <script
            async
            src="https://cdn.avantisvideo.com/avm/js/video-loader.js?id=fecaff7b-ce7b-46dd-9104-5843e7b31318&tagId=8"
            id="avantisJS"
          />
        )}
      </Helmet>
      {children}
    </>
  )
}

/**
 * Root element
 */
const Root: GatsbyBrowser['wrapPageElement'] = ({ element }) => (
  <>
    <Page>{element}</Page>
  </>
)

export default Root

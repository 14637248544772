import React, { ReactElement } from 'react'
import { Link } from 'gatsby'

import { Wrapper } from './styles'
import { ArticleProps } from './types'
import getArticleUrl from '@/utils/routes/get-article-url'
import UserImage from '@/assets/images/user.png'
import LogoFacebook from '@/assets/images/facebook-svgrepo-com.svg'
import LogoTwitter from '@/assets/images/twitter-svgrepo-com.svg'
import LogoPinterest from '@/assets/images/pinterest-svgrepo-com.svg'
import LogoEmail from '@/assets/images/email-svgrepo-com.svg'

/**
 * Article item component
 */
export default function ArticleItem({ article }: ArticleProps): ReactElement {
  const link = getArticleUrl(article)
  const images = article.images || []
  const image = images.find((item) => item.is_featured) || images[0]

  const date = new Date(article.updated_at)
  const formattedDate = new Intl.DateTimeFormat(`en-US`, {
    month: `short`,
    day: `2-digit`,
    year: `numeric`,
  }).format(date)

  return (
    <Wrapper>
      <div className="category-title">
        <span>{article.category}</span>
      </div>
      <div className="post-name">
        <Link to={link}>{article.title}</Link>
      </div>
      <div className="post-author">
        <img className="avatar-img" src={UserImage} alt="User Avatar" />
        By <strong>{article.author_name}</strong>
        &nbsp;&nbsp;|&nbsp;&nbsp;Published on {formattedDate}
        <div className="social-logo">
          <img src={LogoFacebook} alt="facebook logo" />
          <img src={LogoTwitter} alt="twitter logo" />
          <img src={LogoPinterest} alt="pinterest logo" />
          <img src={LogoEmail} alt="email logo" />
        </div>
      </div>
      <div className="post-descr">
        {!!image && <img src={image.path} alt={article.title} />}
        <div
          dangerouslySetInnerHTML={{ __html: article.content }}
          className="content"
        />
      </div>
    </Wrapper>
  )
}

import { AppAction, AppState } from './types'

/**
 * App reducer
 */
export default function reducer(state: AppState, action: AppAction): AppState {
  switch (action.type) {
    case `SetArticles`:
      return {
        ...state,
        articles: {
          ...state.articles,
          ...action.payload,
        },
      }
    case `SetBuilder`:
      return {
        ...state,
        builder: action.payload,
      }
    case `SetMenu`:
      return {
        ...state,
        menu: action.payload,
      }
    case `SetSiteSettings`:
      return {
        ...state,
        siteSettings: action.payload,
      }
    default:
      return state
  }
}

import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Container as GridContainer, PageWrapper } from '../../styles/grid'
import { mobile } from '../../styles/functions'

export const Wrapper = styled(PageWrapper)``

export const Container = styled(GridContainer)`
  ${mobile(css`
    padding: 20px;
  `)}
`

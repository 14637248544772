import React, { ReactElement, useEffect, useState } from 'react'
import { Global } from '@emotion/react'
import { Helmet } from 'react-helmet'

import { global } from '../../styles/global'
import { Content, Wrapper } from './styles'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import { AppProps } from '@/components/app/types'
import { useContext } from '@/components/app/context'
import useClient from '@/utils/hooks/use-client'

/**
 * Theme App component
 */
export default function App({ children }: AppProps): ReactElement {
  const serp = process.env.GATSBY_SERP
  const [state] = useContext()
  const isClient = useClient()

  const [hotjarScript, setHotjarScript] = useState<string>(``)

  useEffect(() => {
    if (isClient) {
      const scriptStr = state.siteSettings.header_tags

      if (scriptStr) {
        // Extract content between <script> tags
        const scriptRegex = /<script.*?>([\s\S]*?)<\/script>/i
        const match = scriptStr.match(scriptRegex)

        if (match && match[1]) {
          setHotjarScript(match[1])
        }
      }
    }
  }, [isClient])

  return (
    <Wrapper>
      <Global styles={global} />
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin=""
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500&display=swap"
          rel="stylesheet"
        />
        <link
          data-n-head="1"
          rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.15.4/css/all.css"
        />
        <script>{hotjarScript}</script>
      </Helmet>
      {!serp && <Header />}
      {state.siteSettings && <Content>{children}</Content>}
      <Footer />
    </Wrapper>
  )
}

import App from './components/app/App'

import Article from './pages/article/Article'
import Builder from './pages/builder/Builder'
import Category from './pages/category/Category'
import Home from './pages/home/Home'
import Menu from './pages/menu/Menu'
import Search from './pages/search/Search'
import NotFound from './pages/error/NotFound'
import Serps from './pages/serps/Serp'
import ArticleSerps from './pages/serps/Article'

import { Theme } from '@/types/theme'

const DefaultTheme: Theme = {
  components: {
    App,
  },
  pages: {
    Article,
    Builder,
    Category,
    Home,
    Menu,
    Search,
    NotFound,
    Serps,
    BingSerps: null,
    YahooSerps: null,
    GoogleSerps: null,
    ArticleSerps,
  },
}

export default DefaultTheme

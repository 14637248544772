import React, { ReactElement } from 'react'
import { Helmet } from 'react-helmet'

import { TrueaudienceProps } from './types'

/**
 * Facebook Pixel component
 */
export default function Pixel({ children }: TrueaudienceProps): ReactElement {
  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          id="ta-page-statistics-script"
          src="https://pixel.tatpek.com/page_statistics_script.min.js?client_id=8880&campaign_id=0001&conv=0"
          charSet="utf-8"
          async
        />

        <noscript>
          {`
          <img height="1" width="1" style="display:none" src="https://api.trueaudience.io/pagestats/pixel.gif?client_id=8880&campaign_id=0001&conv=0"/>
          `}
        </noscript>
      </Helmet>
      {children}
    </>
  )
}

import React, { ReactElement } from 'react'
import { GatsbyBrowser } from 'gatsby'

import { Provider } from './context'
import FacebookPixel from '@/components/integrations/facebook/Pixel'
import GoogleTag from '@/components/integrations/google/Tag'
import TaboolaPixel from '@/components/integrations/taboola/Pixel'
import ClarityPixel from '@/components/integrations/clarity/Pixel'
import TrueaudiencePixel from '@/components/integrations/trueaudience/Pixel'
import { getComponent } from '@/themes'
import { AppProps } from './types'

/**
 * App component
 */
export function App({ children }: AppProps): ReactElement {
  const AppComponent = getComponent(`App`)

  return <AppComponent>{children}</AppComponent>
}

/**
 * Root element
 */
const Root: GatsbyBrowser['wrapRootElement'] = ({ element }) => (
  <Provider>
    <FacebookPixel />
    <TaboolaPixel />
    <GoogleTag />
    <ClarityPixel />
    <TrueaudiencePixel />
    <App>{element}</App>
  </Provider>
)

export default Root

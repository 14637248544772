import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Container as GridContainer } from '../../styles/grid'
import { mobile } from '../../styles/functions'

export const Container = styled(GridContainer)`
  flex-direction: column;
  max-width: 1300px;
  padding: 50px 20px;
  position: relative;

  ${mobile(css`
    padding: 12px;
  `)}
`

export const Wrapper = styled.div`
  display: block;
  padding: 0;
  flex-grow: 1;
`

export const Loader = styled.div`
  display: block;
  margin: 10px;
`

export const AdContent = styled.div`
  p {
    padding: 10px;
    margin: 10px 0px;
  }
`

export const ButtonLink = styled.a`
  padding: 0.5rem 1rem;
  font-weight: 500;
  border-radius: 0.4rem;
  height: fit-content;
  text-align: center;
  font-size: 1.1rem;
  color: white;
  background-color: #52a5c9;
  border: 1px solid #ace6ff;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  &:hover {
    background-color: #6abcdf;
  }
  ${mobile(css`
    width: 100%;
  `)}
`

export const AdItem = styled.a`
  display: block;
  margin: 10px 0;
  border: 1px solid #ace6ff;
  border-radius: 10px;
  padding: 15px;
  background-color: #f9fdff;
  cursor: pointer;
  &:hover {
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  }
  display: flex;
  ${mobile(css`
    flex-direction: column;
  `)}
  .left-block {
    a {
      text-decoration: underline;
      color: #07329e;
      font-size: 18px;
    }

    .ad-title {
      margin-bottom: 7px;
    }

    .ad-description {
      font-size: 14px;
      margin-bottom: 5px;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }

    .ad-link {
      color: #00a3ff;
      font-size: 13px;
    }
  }

  .right-block {
    display: grid;
    grid-template-columns: 50% auto;
    justify-content: left;
    grid-gap: 10px 0;

    .label {
      text-decoration: underline;
      color: #3063e7;
    }
  }

  ${mobile(css`
    grid-template-columns: 1fr;
    grid-gap: 15px;

    .left-block {
      width: 100%;
      margin-bottom: 12px;
    }

    .right-block {
      width: 100%;
      display: block;

      .label {
        margin-bottom: 5px;
      }
    }

    .ad-description {
    }
  `)}
`

export const AdHead = styled.div`
  text-align: right;
  color: #7a7a7a;
`

export const Item = styled.div`
  display: block;
  border-radius: 8px;
  font-size: 20px;
  overflow: hidden;
  margin: 0px 0px 20px 0px;
  padding: 10px;

  &:hover {
    background-color: #73c1ef30;
  }

  a {
    display: inline-block;
    width: 100%;
    color: inherit;
    text-decoration: none;
    box-sizing: border-box;
    position: relative;
    transition: 0.2s background-color ease;

    &:after {
      content: '›';
      position: absolute;
      right: 20px;
      top: 24px;
      font-size: 48px;
      padding: 8px 14px 16px 14px;
      border: 3px rgba(221, 56, 116, 1) solid;
      border-radius: 8px;
      line-height: 20px;
      color: rgba(221, 56, 116, 1);
      display: none;
    }

    .ad-title {
      font-size: 28px;
      font-weight: 500;
      margin-bottom: 12px;
    }
  }

  .ad-description {
    font-size: 16px;
    font-weight: normal;
    padding-top: 5px;
    cursor: pointer;
    font-family: arial, sans-serif;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  a .ad-title {
    color: #1a0dab;
    font-family: arial, sans-serif;
    font-size: 20px;
    line-height: 1.3;
    margin-bottom: 5px;
  }

  a .ad-link {
    color: #202124;
    font-style: normal;
    font-size: 15px;
    line-height: 1.3;
    margin-bottom: 8px;
  }

  a:after {
    content: none !important;
  }
`

export const HeaderSearch = styled.section`
  height: 65px;
  position: relative;
  z-index: 3;
  box-shadow: 0 0 4px 3px rgba(79,79,79,.14);
  width: 100%;

  .wrapper {
    width: 100%;
    max-width: 1300px;
    padding: 0 20px;
    margin: 0 auto;
  }

  .header {
    display: table;
    width: 100%;
  }

  .header-logo {
    width: auto;

    a {
      margin: 0;
      display: block;

      img {
        width: 220px;
        margin-top: 20px;
      }
    }
  }

  .header>* {
    display: table-cell;
    vertical-align: top;
  }

  .header-form {
    width: 350px;
    padding: 11px 0 0;
    
    .no-border {
      border: none;
    }

    form {
      transition-delay: 10ms;
      display: block;
      position: relative;
      border: 1px solid #d9d9d9;
      border-radius: 1.5rem;

      input {
        display: block;
        height: 40px;
        border: none;

        width: 100%;
        font-size: 15px;
        color: #333;
        background: #f3f3f3;
        padding: 10px 35px 10px 20px;
        -webkit-appearance: none;
        border-radius: 1.5rem;

        :focus {
          outline: none;
        }
      }

      button {
        display: block;
        height: 40px;
        border: none;
        background: none;

        position: absolute;
        right: 0;
        top: 0;
        width: 40px;
        cursor: pointer;
      }

      button:before {
        color: #666;
        font-family: icomoon;
        color: #fff;
        font-size: 25px;
        font-weight: 700;
      }
    }
  }

  ${mobile(css`
    height: 50px;

    .wrapper {
      padding: 0 12px;
      box-sizing: border-box;
    }

    .header-logo {
      padding-left: 0;
      padding-right: 10px;

      a {
        height: 50px;
        overflow: hidden;

        img {
          display: inline-block;
          margin: 14px 10px;
          width: 180px;
        }
      }
    }

    .header-form {
      padding: 4px 0 0;
      width: auto;
    }
  `)}
}
`

import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Container as GridContainer } from '../../styles/grid'
import { mobile, rem } from '../../styles/functions'
import { colors, fontWeights } from '../../styles/variables'

export const Container = styled(GridContainer)`
  flex-direction: column;
  max-width: 1300px;
  padding: 0 20px;
  margin-top: 2rem;
  margin-bottom: 5rem;
  position: relative;

  .readmore_button {
    display: block;
    margin-top: 4.375em;
    cursor: pointer;
    font-size: 0.85rem;
    color: #9b9b9b !important;

    width: fit-content;
    border: none;
    background: none;
  }

  ${mobile(css`
    padding: 12px;
  `)}
`

export const Wrapper = styled.div`
  display: block;
  padding: 0;
  flex-grow: 1;
`

export const Loader = styled.div`
  display: block;
  margin: 10px;
`

export const AdContent = styled.div`
  p {
    padding: 10px;
    margin: 10px 0px;
  }
`

export const ButtonLink = styled.a`
  padding: 0.5rem 1rem;
  font-weight: 500;
  border-radius: 0.4rem;
  height: fit-content;
  text-align: center;
  font-size: 1.1rem;
  color: white;
  background-color: #52a5c9;
  border: 1px solid #ace6ff;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  &:hover {
    background-color: #6abcdf;
  }
  ${mobile(css`
    width: 100%;
  `)}
`

export const AdItem = styled.div`
  border: 1px solid #c7e2e7;
  display: block;
  margin: 10px 0;
  border-radius: 10px;
  padding: 1.4rem 1.8rem;
  background-color: #f9fdff;
  -webkit-box-shadow: 0px 0px 11px 1px rgba(199, 226, 231, 0.3);
  -moz-box-shadow: 0px 0px 11px 1px rgba(199, 226, 231, 0.3);
  box-shadow: 0px 0px 11px 1px rgba(199, 226, 231, 0.3);
  flex-direction: row;
  display: flex;
  ${mobile(css`
    flex-direction: column;
    padding: 1.2rem 1rem;
  `)}
  .btn-link-container {
    width: 18%;
    min-width: 12rem;
    ${mobile(css`
      width: 100%;
    `)}
  }

  .half-width {
    width: 50%;
  }

  .full-width {
    width: 100%;
    max-width: 37rem;
  }

  .left-block {
    font-family: 'Inter';

    a {
      display: block;
      width: fit-content;
      font-weight: 500;
    }

    .ad-title {
      color: #305bae;
      width: fit-content;
      font-weight: 700;
      font-size: 1.2rem;
      text-decoration: underline;
      line-height: 1.4;
      margin-bottom: 0.6rem;
      text-decoration-thickness: 2px; /* Adjust thickness */
      text-underline-offset: 3px; /* Adjust offset */
      b {
        font-weight: 700;
      }
    }

    .ad-description {
      width: fit-content;
      color: #565a5a;
      font-size: 1rem;
      margin-bottom: 0.5rem;
      b {
        font-weight: 500;
      }
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .ad-link {
      color: #3db0f5;
      font-size: 1rem;
      width: fit-content;
      font-weight: 600;
    }
  }

  .right-block {
    margin-left: 6rem;
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.4rem;
    .label {
      text-decoration-thickness: 2px; /* Adjust thickness */
      text-underline-offset: 3px; /* Adjust offset */
      text-decoration: underline;
      font-weight: 500;
      color: #2f57db;
    }
  }

  ${mobile(css`
    .left-block {
      width: 100%;
    }

    .right-block {
      margin-left: 0rem;
      width: 100%;
      justify-content: start;
    }

    .ad-description {
    }
  `)}
`

export const AdHead = styled.div`
  text-align: right;
  color: #9f9f9f;
  font-size: 0.8rem;
  font-weight: 500;
`

export const Item = styled.div`
  display: block;
  border-radius: 8px;
  font-size: 20px;
  overflow: hidden;
  margin: 0px 0px 20px 0px;
  padding: 10px;

  &:hover {
    background-color: #73c1ef30;
  }

  a {
    display: inline-block;
    width: 100%;
    color: inherit;
    text-decoration: none;
    box-sizing: border-box;
    position: relative;
    transition: 0.2s background-color ease;

    &:after {
      content: '›';
      position: absolute;
      right: 20px;
      top: 24px;
      font-size: 48px;
      padding: 8px 14px 16px 14px;
      border: 3px rgba(221, 56, 116, 1) solid;
      border-radius: 8px;
      line-height: 20px;
      color: rgba(221, 56, 116, 1);
      display: none;
    }

    .ad-title {
      font-size: 28px;
      font-weight: 500;
      margin-bottom: 12px;
    }
  }

  .ad-description {
    font-size: 16px;
    font-weight: normal;
    padding-top: 5px;
    cursor: pointer;
    font-family: arial, sans-serif;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  a .ad-title {
    color: #1a0dab;
    font-family: arial, sans-serif;
    font-size: 20px;
    line-height: 1.3;
    margin-bottom: 5px;
  }

  a .ad-link {
    color: #202124;
    font-style: normal;
    font-size: 15px;
    line-height: 1.3;
    margin-bottom: 8px;
  }

  a:after {
    content: none !important;
  }
`

export const Title = styled.h1`
  font-size: 2.2rem;
  font-weight: ${fontWeights.medium};
  margin: 0;

  ${mobile(css`
    font-size: ${rem(32)};
    margin-bottom: 20px;
  `)}
`

export const Content = styled.div`
  line-height: ${rem(30)};
  margin-bottom: 20px;
  padding: 0.5rem;

  .content {
    text-align: left;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: ${fontWeights.medium};
  }

  h4 {
    font-size: ${rem(20)};
  }

  blockquote {
    font-size: ${rem(18)};
    font-style: italic;
    border-left: 1px ${colors.grey} solid;
    margin: 0;
    padding-left: 20px;
  }

  ${mobile(css`
    margin-bottom: 20px;

    img {
      width: auto;
      display: block;
      max-width: 100%;
    }
  `)}
`

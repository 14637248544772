import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Link } from 'gatsby'
import { mobile, rem } from '../../styles/functions'

export const Wrapper = styled.section``

export const ImageSlide = styled.section`
  background-color: #140939;

  .slide-container {
    display: block;
    margin: 0 auto;
    max-width: 1160px;
    padding: 50px 0;

    h2 {
      color: #fff;
    }

    .image-no {
      color: #67607d;
      font-size: 1.5em;
    }
  }

  .image-container {
    width: 350px;
    height: 300px;
    float: right;
    display: block;
    border-radius: 4px;
    overflow: hidden;
    margin-right: 70px;
    margin-top: 10px;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 5px 5px 80px 5px;
      overflow: hidden;
    }
  }

  .image-title {
    float: left;
    width: 450px;
    margin-top: 50px;
    margin-left: 70px;
  }
`

export const Container = styled.div`
  display: block;
  margin: 0 auto;
  max-width: 1160px;

  ${mobile(css`
    padding: 20px;
  `)}
`

export const FeaturedSection = styled.div`
  display: block;
  margin-bottom: 40px;

  .general {
    width: 65%;
    float: right;
    margin: 30px 0 30px 30px;
  }

  .art-image {
    height: 400px;
    padding: 0 25px 25px 0;
  }

  ${mobile(css`
    padding: 20px;
  `)}
`
export const ReadMore = styled(Link)`
  color: #54e0c2 !important;
  font-weight: 600;
  font-size: ${rem(16)};
  text-transform: uppercase;
  text-decoration: none;

  span {
    margin-right: 20px;
  }
`

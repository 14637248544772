import { css, SerializedStyles } from '@emotion/react'
import { fontSizes } from './variables'

/**
 * Rem
 */
export function rem(value: number): string {
  return `${(value / fontSizes.root).toFixed(6)}rem`
}

/**
 * Mobile
 */
export function mobile(content: SerializedStyles | string): SerializedStyles {
  return css`
    @media (max-width: 767.99px) {
      ${content}
    }
  `
}

import { css } from '@emotion/react'

import { normalize } from './normalize'
import { typography } from './typography'

/**
 * Global style
 */
export const global = css`
  ${normalize}
  ${typography}
`

export const IS_MOBILE_QUERY = `(max-width: 767.99px)`

import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Container as GridContainer, PageWrapper } from '../../styles/grid'
import { mobile, rem } from '../../styles/functions'
import { colors, fontSizes, fontWeights } from '../../styles/variables'
import tile from '../../assets/images/header-tile.png'

export const Wrapper = styled(PageWrapper)`
  overflow: hidden;
  z-index: -1;
`

export const Container = styled(GridContainer)`
  flex-direction: column;
  padding: 0 30px 0 30px;

  ${mobile(css`
    padding: 0 20px 20px 20px;
  `)}
`

export const Head = styled.div`
  display: block;
  background-color: ${colors.primary};
  background-image: none;
  background-position: bottom left;
  background-repeat: repeat-x;

  .has-featured-image & {
    background-image: url('${tile}');
  }
`

export const Title = styled.h1`
  display: block;
  font-size: ${rem(fontSizes.h1)};
  font-weight: ${fontWeights.medium};
  margin: 0;
  position: relative;
  color: ${colors.contrast};
  padding-top: 20px;
  padding-bottom: 20px;

  ${mobile(css`
    font-size: ${rem(32)};
    margin-bottom: 20px;
    width: 100%;
    margin: 0;
  `)}
`

export const Purple = styled.div`
  display: block;
  position: absolute;
  bottom: 0;
  top: 0;
  left: -100vw;
  right: -100vw;
  background-color: ${colors.primary};
  z-index: -1;
`

export const Image = styled.div`
  display: block;
  background-color: ${colors.lightGrey};
  height: 400px;
  margin-bottom: -80px;
  position: relative;
  width: 100%;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  ${mobile(css`
    height: 200px;
    margin: 0 -20px;
  `)}
`

export const Meta = styled.div`
  margin-bottom: 24px;

  span {
    display: inline-block;
    border-right: 1px ${colors.grey} solid;
    padding-right: 10px;
    color: ${colors.grey};
    font-style: italic;
    font-weight: ${fontWeights.medium};
    font-size: ${rem(14)};
    letter-spacing: -1px;

    &:last-of-type {
      padding-right: 0;
      border-right: none;
    }
  }
`

export const Content = styled.div`
  display: block;
  line-height: ${rem(30)};
  margin-bottom: 20px;
  padding-top: 20px;

  .has-featured-image & {
    padding-top: 90px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: ${fontWeights.medium};
  }

  h4 {
    font-size: ${rem(20)};
  }

  blockquote {
    font-size: ${rem(18)};
    font-style: italic;
    border-left: 1px ${colors.grey} solid;
    margin: 0;
    padding-left: 20px;
  }

  p img {
    width: 100%;
    margin: 20px 0 10px 0;
  }

  ${mobile(css`
    margin-bottom: 0;
  `)}
`

export const CategoriesWrapper = styled.div`
  font-size: ${rem(16)};
  margin-bottom: 20px;
  letter-spacing: -0.5px;

  span {
    display: inline-block;
    margin-right: 4px;
  }

  a {
    display: inline-block;
    color: ${colors.secondary};
    text-decoration: none;
    margin-right: 4px;

    &:hover {
      text-decoration: underline;
    }

    &:after {
      display: inline-block;
      content: ',';
    }

    &:last-of-type {
      margin-right: 0;

      &:after {
        content: none;
      }
    }
  }
`

import { Colors, Fonts, FontSizes, FontWeights } from './types'

/**
 * Colors
 */
export const colors: Colors = {
  contrast: `#ffffff`,
  grey: `#b6b6b6`,
  lightGrey: `#ebebeb`,
  primary: `#201430`,
  secondary: `#ff008f`,
  text: `#161715`,
}

export const fonts: Fonts = {
  primary: `'Work Sans', sans-serif`,
}

export const fontSizes: FontSizes = {
  root: 16,
  h1: 48,
}

export const fontWeights: FontWeights = {
  medium: 500,
  regular: 400,
}

import React, { ReactElement, useEffect, useState } from 'react'

import { InfiniteScroll as InfiniteScrollType } from '@/types/models/article'
import Content from './Content'
import { ContentProps } from './Contents'
import {
  Ads,
  AdSection,
  Container,
  Main,
  SlideBlock,
  SubTitle,
  Title,
  TopBlock,
} from './styles'

/**
 * Infinite Scroll content type
 */
export default function InfiniteScroll({
  article,
  contentId,
  methods,
}: ContentProps): ReactElement {
  const [id, setId] = useState<number>(contentId)
  const contents = (article.articles as InfiniteScrollType[]) || []

  // change state on scroll
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled =
        window.innerHeight + window.scrollY >= document.body.offsetHeight - 100
      if (isScrolled && id < contents.length) {
        window.history.pushState(
          null,
          ``,
          `/article/${article.slug}/${id + 1}/`,
        )
        setId(id + 1)
        methods.properInfNewPage()
        methods.properBuildSlots()
      }
    }

    document.addEventListener(`scroll`, handleScroll, { passive: true })

    return () => {
      document.removeEventListener(`scroll`, handleScroll)
    }
  }, [article, contents, id, methods])

  useEffect(() => {
    if (contentId > 1 && contentId <= contents.length) {
      window.scrollTo({
        top: document.body.offsetHeight - window.innerHeight - 150,
        behavior: `smooth`,
      })
    }
  }, [contentId, contents])

  /**
   * AMG says we don't need to call properSpaNewPage() upon page load
   */
  // useEffect(() => {
  //   // Initial page load
  //   methods.properSpaNewPage()
  // }, [methods])

  useEffect(() => methods.refresh(), [methods])

  return (
    <Main data-active={id}>
      <SlideBlock>
        <Container>
          <TopBlock>
            <Title>{article.title}</Title>
            <AdSection>
              <Ads className="getinfo-infinite" />
              <Ads className="getinfo-infinite" />
              <Ads className="getinfo-mobile" />
            </AdSection>
            <Content html={article.content} insertAd={false} />
          </TopBlock>
        </Container>
      </SlideBlock>

      {contents.slice(0, id).map((content) => (
        <SlideBlock key={content.id}>
          <Container>
            <TopBlock>
              <SubTitle dangerouslySetInnerHTML={{ __html: content.title }} />
              <AdSection>
                <Ads className="getinfo-infinite" />
                <Ads className="getinfo-infinite" />
                <Ads className="getinfo-mobile" />
              </AdSection>
            </TopBlock>
          </Container>
          <Container>
            <div />
            <div>
              {content.image && (
                <img
                  src={content.image}
                  alt={content.image_description || ``}
                />
              )}
              <Content html={content.description} insertAd={false} />
            </div>
            <div />
          </Container>
        </SlideBlock>
      ))}
    </Main>
  )
}

import React, { ReactElement } from 'react'
import { Link } from 'gatsby'

import { FeaturedArticleWrapper } from './styles'
import { ArticlesProps } from './types'
import getArticleUrl from '@/utils/routes/get-article-url'
import BlankImage from '@/assets/images/blank.png'
import htmlToExcerpt from '@/utils/html-to-excerpt'

export default function FeaturedArticle({
  articles,
}: ArticlesProps): ReactElement {
  const article = articles[0]
  const link = getArticleUrl(article)
  const images = article.images || []
  const image = images.find((item) => item.is_featured) || images[0]
  const imageSrc = image ? image.path : BlankImage

  return (
    <FeaturedArticleWrapper>
      <div className="wrapper">
        <div className="hp-block">
          <div className="hp-left">
            <Link to={link}>
              <div
                className="hp-image"
                style={{ backgroundImage: `url(${imageSrc})` }}
              />
            </Link>
          </div>
          <div className="hp-right">
            <div className="hp-info">
              <div className="hp-category">
                <Link to={link}>Featured</Link>
              </div>
              <div className="hp-title">
                <Link to={link}>{article.title}</Link>
              </div>
              <div className="hp-descr">
                <Link
                  to={link}
                  dangerouslySetInnerHTML={{
                    __html: htmlToExcerpt(article.content, 200),
                  }}
                />
              </div>
              <div className="hp-btn">
                <Link to={link}>Read More</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FeaturedArticleWrapper>
  )
}

import React, { ReactElement, useEffect, useRef } from 'react'

interface Props {
  html: string
  insertAd?: boolean
}

/**
 * Article Content
 */
export default function Content({
  html,
  insertAd = true,
}: Props): ReactElement {
  const ref = useRef()
  useEffect(() => {
    if (insertAd && ref.current) {
      const paragraphs = (ref.current as HTMLDivElement).querySelectorAll(`p`)
      const { length } = paragraphs
      const middle =
        paragraphs[Math.floor(length / 2)] || paragraphs[length - 1]
      const insert = document.createElement(`div`)
      insert.setAttribute(`class`, `getinfo-leaderboard`)
      middle.parentElement.insertBefore(insert, middle)
    }
  }, [insertAd, ref])
  return <div ref={ref} dangerouslySetInnerHTML={{ __html: html }} />
}

import React, {
  Context,
  createContext,
  Dispatch,
  ReactElement,
  ReactNode,
  useContext,
  useReducer,
} from 'react'

export interface ContextSet<ContextAction, ContextState> {
  Context: Context<[ContextState, Dispatch<ContextAction>]>
  Provider: (props: ContextProviderProps) => ReactElement
  useContext: () => [ContextState, Dispatch<ContextAction>]
}

export interface ContextProviderProps {
  children: ReactNode
}

export default function createContextSet<ContextAction, ContextState>(
  reducer: (state: ContextState, action: ContextAction) => ContextState,
  defaultState: ContextState,
): ContextSet<ContextAction, ContextState> {
  type ContextDispatch = Dispatch<ContextAction>
  type ContextValue = [ContextState, ContextDispatch]
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const CreatedContext = createContext<ContextValue>([defaultState, () => {}])
  return {
    Context: CreatedContext,
    Provider: function Provider({
      children,
    }: ContextProviderProps): ReactElement {
      const [value, dispatch] = useReducer(reducer, defaultState)
      return (
        <CreatedContext.Provider value={[value, dispatch]}>
          {children}
        </CreatedContext.Provider>
      )
    },
    useContext: (): ContextValue => useContext(CreatedContext),
  }
}

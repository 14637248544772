import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Link } from 'gatsby'
import { mobile, rem } from '../../styles/functions'
import { colors, fontWeights } from '../../styles/variables'

export const ArticlesWrapper = styled.div`
  display: block;
  flex-grow: 1;
  flex-wrap: wrap;
  padding: 24px 0 40px 0;

  ${mobile(css`
    padding: 20px 0;
  `)}

  .articles-ad {
    display: block;
    height: 90px;
    background-color: ${colors.black};
    font-weight: ${fontWeights.medium};
    color: ${colors.contrast};
    text-align: left;
    box-sizing: border-box;
    font-size: ${rem(40)};
    padding: 20px 36px 0 36px;
    margin-bottom: 10px;
  }
`

export const ArticlesContainer = styled.div`
  display: block;
  margin: 0 -12px;
  overflow: auto;

  &:before,
  &:after {
    content: '';
    clear: both;
    display: table;
  }
`

export const Wrapper = styled.div`
  display: inline-block;
  width: 33.3333%;
  padding: 0 12px;
  margin-bottom: 12px;
  box-sizing: border-box;
  float: left;

  a h2 {
    line-height: ${rem(24)};
    font-size: ${rem(20)};
  }

  ${mobile(css`
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
    float: none;
  `)}

  &:first-of-type {
    width: 100%;

    h2 {
      font-size: ${rem(30)};
      margin-bottom: 0;
    }

    .article-image {
      a {
        height: 450px;
      }
    }

    .article-excerpt {
      display: block;
    }
  }
`

export const Image = styled.div`
  display: block;
  overflow: hidden;
  margin: 0;
  width: 100%;
  max-width: 100%;
  background-color: ${colors.secondary};
  margin-bottom: 16px;

  a {
    display: block;
    height: 220px;
    position: relative;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  ${mobile(css`
    min-width: auto;
    width: 100%;
    margin: 0 0 20px 0;
    height: 200px;
  `)}
`

export const Content = styled.div`
  a {
    color: inherit;
    text-decoration: none;
  }

  h2 {
    font-size: ${rem(24)};
    font-weight: ${fontWeights.medium};
    letter-spacing: -2px;
    margin: -8px 0 0 0;
    color: ${colors.primary};
    line-height: ${rem(43)};
    margin-bottom: 14px;
  }
`

export const Meta = styled.div`
  margin-bottom: 24px;

  span {
    display: inline-block;
    border-right: 1px ${colors.grey} solid;
    padding-right: 10px;
    color: ${colors.grey};
    font-style: italic;
    font-weight: ${fontWeights.medium};
    font-size: ${rem(14)};
    letter-spacing: -1px;

    &:last-of-type {
      padding-right: 0;
      border-right: none;
    }
  }
`

export const Excerpt = styled.div`
  font-size: ${rem(16)};
  line-height: ${rem(30)};
  margin-bottom: 16px;
  display: none;

  ${mobile(css`
    line-height: ${rem(20)};
  `)}
`

export const ReadMore = styled(Link)`
  span {
    color: ${colors.secondary};
    font-weight: ${fontWeights.medium};
    font-size: ${rem(13)};
    text-transform: uppercase;
    text-decoration: underline;
  }
`

export const LoadMore = styled.div`
  display: block;
  text-align: center;
  width: 100%;
  margin: 24px 0 16px 0;

  button {
    background-color: ${colors.primary};
    border: none;
    border-radius: 2px;
    color: ${colors.contrast};
    outline: none;
    padding: 16px 36px;
    text-transform: uppercase;
    font-size: ${rem(12)};
    font-weight: ${fontWeights.medium};
    cursor: pointer;
  }
`

export const NoArticles = styled.div`
  font-size: 20px;
`

export const CategoriesContainer = styled.div`
  display: block;
  margin: 0 -12px;
  overflow: auto;
  padding-top: 40px;

  &:before,
  &:after {
    content: '';
    clear: both;
    display: table;
  }
`

export const CategoryWrapper = styled.div`
  display: inline-block;
  width: 33.3333%;
  padding: 0 12px;
  margin-bottom: 24px;
  box-sizing: border-box;
  float: left;

  & > div,
  & > a {
    display: flex;
    flex-direction: row;
    text-decoration: none;
    color: inherit;
  }

  .category-header {
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;

    img {
      width: 32px;
      margin-right: 8px;
    }

    span {
      display: flex;
      flex-direction: row;
      font-weight: ${fontWeights.medium};
      color: ${colors.black};
      flex-grow: 1;

      &:after {
        content: '';
        flex-grow: 1;
        background-color: red;
        height: 4px;
        margin-top: 6px;
        margin-left: 16px;
      }
    }
  }

  .category-article-image {
    min-width: 86px;
    max-width: 86px;
    height: 72px;
    background-color: ${colors.secondary};
    margin-right: 12px;
    margin-bottom: 12px;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .category-article-title {
    font-size: ${rem(14)};
    font-weight: ${fontWeights.medium};
  }

  ${mobile(css`
    width: 100%;
    float: none;

    .category-article-title {
      font-size: ${rem(16)};
      font-weight: ${fontWeights.medium};
    }
  `)}
`

import axios, { AxiosInstance } from 'axios'

let client: AxiosInstance

export default function getClient(): AxiosInstance {
  if (client) {
    return client
  }

  client = axios.create({
    baseURL: process.env.GATSBY_API_ROOT || ``,
    params: {
      token: process.env.GATSBY_API_TOKEN,
    },
  })

  return client
}

import React, { ReactElement } from 'react'
import { Helmet } from 'react-helmet'

import { MenuPageProps } from '@/types/pages/menu'
import { Container, Content, Title, Wrapper } from './styles'

/**
 * Menu page
 */
export default function Menu({ pageContext }: MenuPageProps): ReactElement {
  const { articles, menuItem } = pageContext

  return (
    <Wrapper>
      <Helmet>
        <title>{`${pageContext.siteSettings.title} - ${menuItem.title}`}</title>
      </Helmet>
      <Container>
        {!!articles.length && (
          <div>
            <Title>{articles[0].title}</Title>
            <Content>
              <div
                dangerouslySetInnerHTML={{ __html: articles[0].content }}
                className="content"
              />
            </Content>
          </div>
        )}
      </Container>
    </Wrapper>
  )
}

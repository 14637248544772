import { useCallback } from 'react'
import isFunction from 'lodash/isFunction'

export type TrackMethod = (action?: string) => void

/**
 * Use track
 */
export default function useTrack(): TrackMethod {
  return useCallback((action = `Lead`) => {
    if (isFunction((window as any).fbq)) {
      ;(window as any).fbq(`track`, action)
    }
  }, [])
}

import React, { ReactElement } from 'react'
import { Helmet } from 'react-helmet'

import { Container, Wrapper } from './styles'
import { HomePageProps } from '@/types/pages/home'
import getRandomArticles from '@/utils/get-random-articles'
import FeaturedArticle from '../../components/article/FeaturedArticle'
import PostArticles from '../../components/article/PostArticles'

/**
 * Home page
 */
export default function Home({ pageContext }: HomePageProps): ReactElement {
  const featuredArticles = getRandomArticles(
    pageContext.articles,
    `featured`,
    4,
  )

  const postArticles = pageContext.articles
    .filter((article) => article.menus === 0)
    .sort((a, b) => a.updated_at.localeCompare(b.updated_at))
    .reverse()

  return (
    <Wrapper>
      <Helmet>
        <title>{pageContext.siteSettings.title} - Home</title>
      </Helmet>
      <Container>
        {featuredArticles.length > 0 && (
          <FeaturedArticle articles={featuredArticles} />
        )}
        {postArticles.length > 0 && <PostArticles articles={postArticles} />}
      </Container>
    </Wrapper>
  )
}

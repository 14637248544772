import React, { ReactElement, useEffect } from 'react'
import { navigate } from 'gatsby'

import { Gallery as GalleryType } from '@/types/models/article'
import { ContentProps } from './Contents'
import {
  Ads,
  AdSection,
  Container,
  Main,
  SubTitle,
  Title,
  TopBlock,
} from './styles'

/**
 * Gallery content type
 */
export default function Gallery({
  article,
  contentId,
  methods,
  layout,
}: ContentProps): ReactElement {
  const contents = (article.articles as GalleryType[]) || []
  const gallery = contents[contentId - 1]
  if (!gallery) {
    return <></>
  }
  const lastGallery = contents.length === contentId

  const layoutParam = layout ? `?layout=${layout}` : ``

  useEffect(() => {
    methods.properSpaNewPage()
  }, [contentId, methods])

  useEffect(() => methods.refresh(), [methods])

  return (
    <Main>
      <Container>
        <TopBlock>
          <Title>{article.title}</Title>
          <Ads className="getinfo-leaderboard" />
          <SubTitle dangerouslySetInnerHTML={{ __html: gallery.title }} />
        </TopBlock>
      </Container>
      <Container>
        <AdSection>
          <Ads className="getinfo-left-1" />
          <Ads className="getinfo-left-2" />
        </AdSection>
        <div>
          {!!gallery.image && <img src={gallery.image} alt="" />}
          <AdSection>
            <div dangerouslySetInnerHTML={{ __html: gallery.description }} />
            <Ads className="getinfo-mobile" />
          </AdSection>
          <Ads className="getinfo-leaderboard" />
          {!lastGallery && (
            <button
              className="green-arrow-button"
              type="button"
              onClick={() => {
                navigate(
                  `/article/${article.slug}/${contentId + 1}/${layoutParam}`,
                )
              }}
            >
              NEXT PAGE &gt;&gt;
            </button>
          )}
        </div>
      </Container>
    </Main>
  )
}

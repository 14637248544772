exports.components = {
  "component---src-components-templates-article-tsx": () => import("./../../../src/components/templates/article.tsx" /* webpackChunkName: "component---src-components-templates-article-tsx" */),
  "component---src-components-templates-home-tsx": () => import("./../../../src/components/templates/home.tsx" /* webpackChunkName: "component---src-components-templates-home-tsx" */),
  "component---src-components-templates-menu-tsx": () => import("./../../../src/components/templates/menu.tsx" /* webpackChunkName: "component---src-components-templates-menu-tsx" */),
  "component---src-components-templates-search-tsx": () => import("./../../../src/components/templates/search.tsx" /* webpackChunkName: "component---src-components-templates-search-tsx" */),
  "component---src-components-templates-serps-article-tsx": () => import("./../../../src/components/templates/serps/article.tsx" /* webpackChunkName: "component---src-components-templates-serps-article-tsx" */),
  "component---src-components-templates-serps-serp-tsx": () => import("./../../../src/components/templates/serps/serp.tsx" /* webpackChunkName: "component---src-components-templates-serps-serp-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}


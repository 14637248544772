import React, { ReactElement } from 'react'
import { Helmet } from 'react-helmet'

export default function Tag(): ReactElement {
  const url = typeof window !== `undefined` ? window.location.href : ``
  let googleTagId = ``
  if (url.includes(`socialsearchtoday.com`)) {
    googleTagId = `G-2KK4S01QXY`
  } else if (url.includes(`sociallysearching.com`)) {
    googleTagId = `G-ZQ1K8L01RG`
  } else if (url.includes(`socialsearched.com`)) {
    googleTagId = `G-XCCTSVYTVX`
  } else if (url.includes(`socialsearchhelp.com`)) {
    googleTagId = `G-C21T3NQBGZ`
  } else if (url.includes(`socialsearchit.com`)) {
    googleTagId = `G-LW78892132`
  } else if (url.includes(`socialsearchlive.com`)) {
    googleTagId = `G-LWG8GCFWBS`
  } else if (url.includes(`socialsearchresults.com`)) {
    googleTagId = `G-6FF506X828`
  } else {
    return null
  }
  return (
    <>
      <Helmet>
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${googleTagId}`}
        />
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${googleTagId}');
          `}
        </script>
      </Helmet>
    </>
  )
}

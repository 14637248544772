import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Container as GridContainer } from '../../styles/grid'
import { mobile, rem } from '../../styles/functions'
import { colors, fontWeights } from '../../styles/variables'

export const Wrapper = styled.div`
  flex-grow: 1;

  ul {
    list-style-type: none;
    display: inline-block;
    margin: 0;
    padding: 0;
  }

  li {
    display: inline-block;
    font-size: ${rem(14)};
    font-weight: ${fontWeights.medium};
    margin-right: 14px;

    a {
      color: ${colors.grey};
      text-decoration: none;

      &:after {
        content: '/';
        margin-left: 14px;
      }
    }

    &:last-of-type {
      margin-right: 0;

      a {
        color: ${colors.secondary};

        &:after {
          content: none;
        }
      }
    }
  }
`

export const Container = styled(GridContainer)`
  max-width: 1440px;
  padding-top: 20px;
  padding-bottom: 34px;
  border-bottom: 1px ${colors.lightGrey} solid;

  ${mobile(css`
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  `)}
`

import styled from '@emotion/styled'

/**
 * App styles
 */
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

export const Content = styled.section`
  flex-grow: 1;
`

import { Menu } from '@/types/models/menu'
import getProperSlug from './get-proper-slug'

/**
 * Get menu URL
 */
export default function getMenuUrl(menu: Menu): string {
  const slug = getProperSlug(menu.slug)
  const prefix = slug === `/` ? `` : `/`
  return `${prefix}${slug}`
}

import styled from '@emotion/styled'

/**
 * Advertisement styles
 */
export const Wrapper = styled.div`
  display: block;
  padding: 20px 0;
  flex-grow: 1;

  #topAd2 .bing-result {
    font-size: 20px;
    margin-bottom: 10px;
  }

  #topAd2 .main-unit {
    border-radius: 8px;
    background-color: rgba(31, 21, 46, 1);
    text-decoration: none;
    padding: 28px 32px 32px 32px;
    box-sizing: border-box;
    transition: 0.2s background-color ease;
  }

  #topAd2 .unit-title a {
    color: #1964d3 !important;
    font-size: 23px;
    margin-bottom: 5px;
  }

  #topAd2 .unit-url a {
    color: green !important;
    font-size: 16px;
  }

  #topAd2 .unit-desc {
    width: 60%;
  }

  #topAd2 .unit-desc a {
    font-size: 18px;
    font-weight: 500;
    color: #fff !important;
  }

  #topAd2 .sponsored-listing {
    display: none;
  }
`

export const Loader = styled.div`
  display: block;
`

export const Item = styled.div`
  display: block;
  border-radius: 8px;
  font-size: 20px;
  margin-bottom: 10px;
  overflow: hidden;
  color: #ffffff;

  a {
    display: inline-block;
    width: 100%;
    color: inherit;
    text-decoration: none;
    padding: 28px 32px 32px 32px;
    box-sizing: border-box;
    position: relative;
    transition: 0.2s background-color ease;
    background-color: rgba(31, 21, 46, 1);

    &:after {
      content: '›';
      position: absolute;
      right: 20px;
      top: 24px;
      font-size: 48px;
      padding: 8px 14px 16px 14px;
      border: 3px rgba(221, 56, 116, 1) solid;
      border-radius: 8px;
      line-height: 20px;
      color: rgba(221, 56, 116, 1);
    }

    &:hover {
      background-color: rgba(59, 44, 88, 1);
    }

    .ad-title {
      font-size: 28px;
      font-weight: 700;
      margin-bottom: 12px;
    }

    .ad-description {
      font-size: 18px;
      font-weight: 500;
    }

    .ad-button {
      display: inline-block;
      font-size: 20px;
      font-weight: 700;
      padding: 16px 72px 16px 20px;
      background-color: rgba(221, 56, 116, 1);
      border-radius: 8px;
      margin-top: 32px;
      position: relative;

      &:after {
        content: '›';
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 32px;
        padding: 2px 10px 6px 10px;
        border: 0;
        border-radius: 50%;
        line-height: 24px;
        color: rgba(221, 56, 116, 1);
        background-color: #ffffff;
      }
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  a.ad-yahoo .ad-title {
    color: #1964d3;
    font-size: 23px;
    margin-bottom: 5px;
  }

  a.ad-yahoo .ad-link {
    color: green;
    font-size: 16px;
  }

  a.ad-yahoo:after {
    content: none !important;
  }

  .ad-color-scheme-2 & {
    color: rgba(221, 56, 116, 1);

    a {
      background-color: rgba(242, 242, 242, 1);

      &:hover {
        background-color: rgba(229, 229, 229, 1);
      }

      .ad-description {
        color: #201430;
      }

      .ad-button {
        color: #ffffff;
      }
    }
  }

  .ad-color-scheme-3 & {
    color: #ffffff;

    a {
      background-color: rgba(26, 19, 196, 1);

      &:after {
        border-color: rgba(110, 191, 75, 1);
        color: rgba(110, 191, 75, 1);
      }

      &:hover {
        background-color: rgba(41, 30, 162, 1);
      }

      .ad-button {
        color: #ffffff;
        background-color: rgba(110, 191, 75, 1);

        &:after {
          color: rgba(110, 191, 75, 1);
        }
      }
    }
  }

  .ad-color-scheme-4 & {
    color: #ffffff;

    a {
      background-color: rgba(242, 242, 242, 1);

      &:after {
        border-color: rgba(26, 19, 196, 1);
        color: rgba(26, 19, 196, 1);
      }

      &:hover {
        background-color: rgba(229, 229, 229, 1);
      }

      .ad-title {
        color: rgba(26, 19, 196, 1);
      }

      .ad-description {
        color: #201430;
      }

      .ad-button {
        color: #ffffff;
        background-color: rgba(26, 19, 196, 1);

        &:after {
          color: rgba(26, 19, 196, 1);
        }
      }
    }
  }
`

import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { mobile } from '@/utils/styles'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 20px;

  .getinfo-mobile {
    display: none;
    margin-bottom: 20px;
  }

  ${mobile(css`
    flex-direction: column;
    max-width: 100%;
    margin: 0;

    .getinfo-mobile {
      display: block;
    }

    .getinfo-infinite,
    .getinfo-leaderboard {
      display: none;
    }
  `)}
`

export const Left = styled.div`
  position: relative;
  max-width: 300px;
  min-width: 300px;
  margin-right: 20px;

  > div {
    position: sticky;
    top: 0;
    padding-top: 40px;
  }

  ${mobile(css`
    display: none;
    max-width: 100%;
    min-width: auto;
    margin: 0;
  `)}
`

export const ArticleContent = styled.div`
  flex-grow: 1;

  .getinfo-leaderboard {
    margin-bottom: 20px;
  }

  ${mobile(css`
    flex-grow: 0;
  `)}
`

export const Right = styled.div`
  position: relative;
  max-width: 300px;
  min-width: 300px;
  margin-left: 20px;

  > div {
    position: sticky;
    top: 0;
    padding-top: 40px;
  }

  ${mobile(css`
    display: none;
    max-width: 100%;
    min-width: auto;
    margin: 0;
  `)}
`

export const Main = styled.main`
  padding-top: 40px;
  padding-bottom: 40px;

  p {
    margin-bottom: 20px;
  }

  img {
    max-width: 100%;
    height: auto;
    margin-bottom: 15px;
    width: 100%;
  }

  ${mobile(css`
    padding-top: 20px;
    padding-bottom: 0;
  `)}
`

export const Container = styled.div`
  position: relative;
  display: block;
  padding: 0;
`

export const TopBlock = styled.div`
  display: block;
`

export const Title = styled.h1`
  font-size: 1.875rem;
  font-weight: 600;
`

export const SubTitle = styled.h3`
  font-size: 24px;
  font-weight: 600;
`

export const AdSection = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: center;
  margin-bottom: 20px;

  > div {
    margin: 0 10px;
  }

  ${mobile(css`
    flex-direction: column;
    margin: 0;
  `)}
`

export const Ads = styled.div`
  margin-bottom: 20px;
`

export const buttons = css`
  .green-arrow-button,
  .green-button {
    position: relative;
    background-color: #52c528;
    border: 0;
    width: 100%;
    padding: 14px;
    color: #fff;
    font-size: 22px;
    font-weight: 700;
    cursor: pointer;
    height: 62px;
    transition: 0.4s ease all;
    margin-bottom: 20px;

    &:hover {
      background-color: #3f9e1d;
    }

    @media (min-width: 768px) {
      font-size: 30px;
    }
  }

  .green-arrow-button {
    &:before,
    &:after {
      content: '';
      width: 0;
      height: 0;
      border-top: 31px solid transparent;
      border-bottom: 31px solid transparent;
      position: absolute;
      top: 0;
    }

    &:before {
      left: 0;
      border-left: 20px solid #fff;
    }

    &:after {
      border-left: 20px solid #52c528;
      background-color: #fff;
      right: 0;
      transition: 0.4s ease all;
    }

    &:hover {
      &:after {
        border-left-color: #3f9e1d;
      }
    }
  }
`

export const ConvoBubble = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  background-color: #f1f1f1;
  border: 1px solid #d8d8d8;
  border-radius: 15px;
  padding: 30px;
  margin-bottom: 50px;
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    margin: 0 auto;
    transform: translateY(100%);
    left: 0;
    right: 0;
  }

  &:before {
    bottom: 0;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-top: 24px solid #d8d8d8;
  }

  &:after {
    bottom: 1px;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 24px solid #f1f1f1;
  }

  div {
    margin-bottom: 15px;
  }

  .correct {
    color: #52c528;

    span {
      background-color: #52c528;
    }
  }

  .wrong {
    color: #c52828;
    span {
      background-color: #c52828;
    }
  }

  .correct,
  .wrong {
    font-size: 35px;

    span {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 10px;
    }

    img {
      margin-bottom: 0;
    }
  }
`

export const SlideBlock = styled.article`
  margin-bottom: 40px;
`

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { mobile, rem } from '../../styles/functions'
import { colors, fontWeights } from '../../styles/variables'

export const Wrapper = styled.div`
  min-width: 300px;
  max-width: 300px;
  margin-left: 24px;

  h3 {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;

    img {
      width: 32px;
      margin-right: 10px;
    }

    span {
      flex-grow: 1;
      font-size: ${rem(16)};
    }
  }

  .sidebar-ad {
    display: block;
    height: 250px;
    background-color: ${colors.black};
    margin-bottom: 20px;
    font-weight: ${fontWeights.medium};
    color: ${colors.contrast};
    text-align: center;
    box-sizing: border-box;
    padding-top: 30px;
    font-size: ${rem(40)};
  }

  ${mobile(css`
    max-width: 100%;
    min-width: auto;
    margin: 0;
  `)}
`

export const Articles = styled.div`
  margin-bottom: 40px;

  & > a {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    color: inherit;
    text-decoration: none;
  }

  .sidebar-article-image {
    background-color: ${colors.secondary};
    min-width: 115px;
    max-width: 115px;
    height: 90px;
    margin-right: 8px;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  h4 {
    flex-grow: 1;
    margin: 0;
  }

  ${mobile(css`
    max-width: 100%;
    min-width: auto;
    margin: 0;
  `)}
`

import styled from '@emotion/styled'

import { rem } from '../../styles/functions'
import { Container as GridContainer, PageWrapper } from '../../styles/grid'
import { fontSizes, fontWeights } from '../../styles/variables'

export const Wrapper = styled(PageWrapper)`
  padding: 60px;
`

export const Container = styled(GridContainer)``

export const Title = styled.h1`
  font-size: ${rem(fontSizes.h1)};
  font-weight: ${fontWeights.medium};
  margin: 0 0 32px 0;
`

import { useEffect, useState } from 'react'

/**
 * Check if render is done in client-side
 */
export default function useClient(): boolean {
  const [isClient, setIsClient] = useState<boolean>(false)
  useEffect(() => {
    setIsClient(true)
  }, [])
  return isClient
}

import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Container as GridContainer } from '../../styles/grid'
import { mobile } from '../../styles/functions'

export const Container = styled(GridContainer)`
  flex-direction: column;
  max-width: 1300px;
  padding: 50px 20px;
  position: relative;

  ${mobile(css`
    padding: 12px;
  `)}
`

export const Wrapper = styled.div`
  display: block;
  padding: 0;
  flex-grow: 1;
`

import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Link } from 'gatsby'
import { mobile, rem } from '../../styles/functions'
import { colors, fontWeights } from '../../styles/variables'

export const ArticlesWrapper = styled.div`
  padding: 20px 0;
  float: left;
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 50px;

  ${mobile(css`
    padding: 20px 0;
  `)}
`

export const FeaturedArticlesWrapper = styled.div`
  border-top: 2px solid #f2f1f1;
  border-right: 2px solid #f2f1f1;
  border-left: 2px solid #f2f1f1;
  margin: 30px 0;
  display: inline-block;

  .featured {
    border-bottom: 2px solid #f2f1f1;
    padding: 20px;
    width: 330px;
  }

  .section-title {
    text-transform: uppercase;
    font-family: 'Poppins';
    font-size: 30px;
    line-height: 23px;
    font-weight: 700;
    margin-bottom: 40px;
    color: #01c08e;
    position: relative;
    padding-top: 11px;
    margin: 20px;
  }

  .section-title p {
    font-weight: 400;
    font-size: 22px;
    color: #333;
    margin: 0px;

    i {
      color: #54e0c2;
      margin-left: 90px;
      font-size: 15px;
    }
  }

  .section-title:after {
    content: '';
    position: absolute;
    width: 150px;
    height: 67px;
    background: #c4fff2;
    left: 80px;
    top: 0;
    z-index: -1;
  }

  ${mobile(css`
    padding: 20px 0;
  `)}
`

export const TrendingArticlesWrapper = styled.div`
  margin: 30px 0;

  .list {
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 50px;
  }

  .art-image {
    height: 300px;
  }

  .section-title {
    text-transform: uppercase;
    font-family: 'Poppins';
    font-size: 30px;
    line-height: 23px;
    font-weight: 700;
    margin-bottom: 40px;
    color: #01c08e;
    position: relative;
    padding-top: 11px;
  }

  .section-title p {
    font-weight: 400;
    font-size: 22px;
    color: #333;
    margin: 0px;

    i {
      color: #54e0c2;
      margin-left: 90px;
      font-size: 15px;
    }
  }

  .section-title:after {
    content: '';
    position: absolute;
    width: 150px;
    height: 67px;
    background: #c4fff2;
    left: 80px;
    top: 0;
    z-index: -1;
  }

  ${mobile(css`
    padding: 20px 0;
  `)}
`

export const Wrapper = styled.div`
  margin-bottom: 10px;
  width: 350px;

  &:last-of-type {
    margin-bottom: 0;
  }

  ${mobile(css`
    flex-direction: column;
    margin-bottom: 40px;
  `)}
`

export const Image = styled.div`
  display: block;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 42px;
  margin-top: 10px;
  width: 100%;

  a {
    display: block;
    background-color: transparent;
    height: 240px;
    position: relative;
    padding: 0 12px 12px 0;

    &:after {
      content: '';
      position: absolute;
      top: 12px;
      right: 0;
      bottom: 0;
      left: 16px;
      background: #fdfce4;
      z-index: -1;
      border-radius: 17px !important;
      transition: all 0.3s linear;
    }

    &:hover:after {
      background: #c4fff2;
    }
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 5px 5px 80px 5px;
    overflow: hidden;
  }

  ${mobile(css`
    min-width: auto;
    width: 100%;
    margin: 0 0 20px 0;
    height: 200px;
  `)}
`

export const Content = styled.div`
  a {
    color: inherit;
    text-decoration: none;
  }

  h3:hover {
    color: ${colors.primary};
  }

  h3 {
    font-size: ${rem(22)};
    font-weight: ${fontWeights.regular};
    letter-spacing: -2px;
    margin: -8px 0 0 0;
    color: #333;
    line-height: ${rem(30)};
    margin-bottom: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .post-no {
    font-size: 20px;
    line-height: 30px;
    display: inline-block;
    border-bottom: 3px solid #313131;
    font-weight: 600;
    color: #313131;
    margin-bottom: 10px;
  }
`

export const Meta = styled.div`
  margin-bottom: 24px;

  span {
    display: inline-block;
    border-right: 1px ${colors.grey} solid;
    padding-right: 10px;
    color: ${colors.grey};
    font-style: italic;
    font-weight: ${fontWeights.medium};
    font-size: ${rem(14)};
    letter-spacing: -1px;

    &:last-of-type {
      padding-right: 0;
      border-right: none;
    }
  }
`

export const Excerpt = styled.div`
  font-size: ${rem(16)};
  line-height: ${rem(20)};
  margin-bottom: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  min-height: 40px;

  ${mobile(css`
    line-height: ${rem(20)};
  `)}
`

export const ReadMore = styled(Link)`
  color: #54e0c2 !important;
  font-weight: 600;
  font-size: ${rem(16)};
  text-transform: uppercase;

  span {
    margin-right: 20px;
  }
`

export const LoadMore = styled.div`
  display: block;
  text-align: center;
  width: 215%;
  margin: 20px 0;

  button {
    background-color: ${colors.primary};
    border: none;
    border-radius: 2px;
    color: ${colors.contrast};
    outline: none;
    padding: 16px 36px;
    text-transform: uppercase;
    font-size: ${rem(12)};
    font-weight: ${fontWeights.medium};
    cursor: pointer;
  }
`

export const NoArticles = styled.div`
  font-size: 20px;
`

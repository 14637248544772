import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { mobile } from '../../styles/functions'

export const Wrapper = styled.section`
  background: #f8f8f8;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin-top: 2.5em;
  margin-bottom: -2em;
  text-align: center;

  .bottom_logo {
    margin-left: 0.25em;
  }

  .logo {
    height: 50px;
    max-width: 220px;
    object-fit: contain;
  }

  .footer_links {
    list-style-type: none;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 0;
    padding: 0;

    li {
      margin: 0 10px;
    }
  }

  ${mobile(css`
    padding: 0 12px 5px 12px;
    box-sizing: border-box;
  `)}
`

import React, { ReactElement, useEffect, useState } from 'react'

import AIArticle from './AIArticle'
import { SearchArticlesWrapper, NoArticles } from './styles'
import { AIArticlesProps } from './types'
import useClient from '@/utils/hooks/use-client'

export default function SearchAIArticles({
  aiArticles,
}: AIArticlesProps): ReactElement {
  const [page, setPage] = useState<number>(1)
  const isClient = useClient()

  useEffect(() => {
    setPage(1)
  }, [aiArticles])

  const currentArticle = aiArticles[page - 1]

  return (
    <SearchArticlesWrapper>
      {isClient && !aiArticles.length ? (
        <NoArticles>No article(s) found</NoArticles>
      ) : (
        currentArticle && <AIArticle aiArticle={currentArticle} />
      )}
    </SearchArticlesWrapper>
  )
}

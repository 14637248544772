import getClient from './client'

export default async function verifyRecaptchaV3(
  siteID,
  recaptchaToken,
  domain,
) {
  if (siteID === undefined) return null

  const { data } = await getClient().post(
    `adpartners/${siteID}/verifyRecaptchaV3`,
    {
      recaptcha_token: recaptchaToken,
      domain,
    },
  )

  const result = data || null
  return result
}

import { FC } from 'react'

import { BaseComponentProps } from '@/types'
import { Theme } from '@/types/theme'
import Default from './default'
import Theme1 from './theme-1'
import Theme2 from './theme-2'
import Theme3 from './theme-3'
import Theme4 from './theme-4'
import Theme5 from './theme-5'
import Theme6 from './theme-6'
import Theme7 from './theme-7'
import Theme8 from './theme-8'
import Theme9 from './theme-9'
import Theme10 from './theme-10'
import Theme11 from './theme-11'
import Theme12 from './theme-12'
import Theme13 from './theme-13'
import Theme14 from './theme-14'

/**
 * Get theme
 */
export function theme(): Theme {
  switch (process.env.GATSBY_THEME_HANDLE) {
    case `theme-1`:
      return Theme1
    case `theme-2`:
      return Theme2
    case `theme-3`:
      return Theme3
    case `theme-4`:
      return Theme4
    case `theme-5`:
      return Theme5
    case `theme-6`:
      return Theme6
    case `theme-7`:
      return Theme7
    case `theme-8`:
      return Theme8
    case `theme-9`:
      return Theme9
    case `theme-10`:
      return Theme10
    case `theme-11`:
      return Theme11
    case `theme-12`:
      return Theme12
    case `theme-13`:
      return Theme13
    case `theme-14`:
      return Theme14
    default:
      return Default
  }
}

/**
 * Get component
 */
export function getComponent(
  name: keyof Theme['components'],
): FC<BaseComponentProps> {
  return theme().components[name]
}

/**
 * Get page
 */
export function getPage<PageProps>(name: string): FC<PageProps> {
  return theme().pages[name]
}

import styled from '@emotion/styled'

/**
 * Grid components
 */
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1170px;
  margin: 0 auto;
`

import { css } from '@emotion/react'

import { rem } from './functions'
import { colors, fonts, fontSizes } from './variables'

/**
 * Typography
 */
export const typography = css`
  html {
    color: ${colors.text};
    font-family: ${fonts.primary};
    font-size: ${rem(fontSizes.root)};
    font-smooth: auto;
    -webkit-font-smoothing: antialiased;
  }
`

import React, { ReactElement, useEffect, useState } from 'react'

import Article from './Article'
import { PostArticlesWrapper } from './styles'
import { ArticlesProps } from './types'

const initial = 8

export default function PostArticles({
  articles,
}: ArticlesProps): ReactElement {
  const [limit, setLimit] = useState<number>(initial)

  useEffect(() => {
    setLimit(initial)
  }, [articles])

  return (
    <PostArticlesWrapper>
      <div className="wrapper">
        <div className="post-title">Latest Articles</div>
        <div className="post-items">
          {articles.slice(0, limit).map((article) => (
            <Article key={article.id} article={article} />
          ))}
        </div>
      </div>
    </PostArticlesWrapper>
  )
}

import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Container as GridContainer } from '../../styles/grid'
import { mobile, rem } from '../../styles/functions'
import { colors, fontWeights } from '../../styles/variables'

export const Wrapper = styled.section`
  background-color: ${colors.primary};
  color: ${colors.contrast};
  height: 100px;

  ${mobile(css`
    height: 80px;
  `)}
`

export const Container = styled(GridContainer)`
  max-width: 1440px;

  ${mobile(css`
    padding: 0 20px;
    height: 100%;
  `)}
`

export const Logo = styled.div`
  display: inline-block;
  width: 190px;
  margin-top: 20px;

  img {
    max-width: 100%;
  }

  ${mobile(css`
    max-width: 150px;
  `)}
`

export const MenuWrapper = styled.ul`
  display: inline-block;
  list-style-type: none;
  margin: 40px 0 0 248px;
  padding: 0;
  flex-grow: 1;

  li {
    display: inline-block;
    margin: 0;
    margin-right: 66px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  a {
    color: ${colors.contrast};
    text-decoration: none;
    text-transform: uppercase;
    font-size: ${rem(13)};
    font-weight: ${fontWeights.medium};

    &.is-active {
      color: ${colors.secondary};
    }
  }

  ${mobile(css`
    margin: 0;
    text-align: right;
    flex-grow: 1;
    align-self: center;

    li {
      margin-right: 20px;
      margin-bottom: 4px;
    }
  `)}
`

export const SearchWrapper = styled.div`
  display: inline-block;
  padding-top: 30px;

  form {
    display: flex;
    flex-direction: row;
    position: relative;
  }

  input {
    display: inline-block;
    outline: none;
    padding: 12px 30px 12px 16px;
    border-radius: 4px;
    border: none;
    width: 320px;
  }

  button {
    position: absolute;
    right: 4px;
    top: 50%;
    background-color: transparent;
    border: none;
    color: ${colors.primary};
    transform: translate3d(0, -50%, 0);
    font-size: 18px;
  }

  ${mobile(css`
    input {
      width: auto;
    }
  `)}
`

import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { mobile } from '../../styles/functions'
import { colors } from '../../styles/variables'

export const Wrapper = styled.section``

export const Container = styled.div`
  display: block;
  margin: 0 auto;
  max-width: 1160px;

  ${mobile(css`
    padding: 20px;
  `)}
`

export const SearchSection = styled.div`
  float: left;
`

export const FeaturedSection = styled.div`
  float: right;
  margin-top: 20px;
`

export const SearchInput = styled.div`
  display: flex;
  flex-direction: column;

  input {
    flex-grow: 1;
    display: inline-block;
    outline: none;
    padding: 12px 30px 12px 16px;
    border-radius: 4px;
    border: 1px ${colors.primary} solid;
    font-size: 24px;
  }

  div {
    font-size: 28px;
    line-height: 33px;
    margin-bottom: 20px;
    color: #01c08e;
    margin-top: 20px;
  }

  span {
    color: #464646;
  }
`

export const Loader = styled.div`
  display: block;
  margin: 10px;
`

import React, { ReactElement, useEffect } from 'react'
import { css, Global } from '@emotion/react'

import { BuilderSitePage } from '@/types/models/builder'

interface Props {
  id?: string
  page: BuilderSitePage
}

/**
 * Experience Builder component
 */
export default function Builder({
  id = `experience-builder-page`,
  page,
}: Props): ReactElement {
  useEffect(() => {
    if (id && page.articles) {
      Object.keys(page.articles).forEach((articleId) => {
        const articleWrapper = document
          .getElementById(id)
          .querySelector(`[data-article-id="${articleId}"]`)
        if (articleWrapper) {
          articleWrapper.innerHTML = page.articles[articleId]?.content || ``
        }
      })
    }
  }, [id, page])
  return (
    <>
      <Global
        // Wrap styling with specified parent element ID
        styles={css`
          #${id} {
            ${page.styling}
          }
        `}
      />
      <section id={id} dangerouslySetInnerHTML={{ __html: page.html }} />
    </>
  )
}

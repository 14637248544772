import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Container as GridContainer } from '../../styles/grid'
import { mobile, rem } from '../../styles/functions'
import { colors, fontWeights } from '../../styles/variables'

export const Wrapper = styled.section`
  background-color: #2f455c;
  color: ${colors.grey};
  height: 70px;

  ${mobile(css`
    height: 60px;
  `)}
`

export const Container = styled(GridContainer)`
  max-width: 1160px;

  ${mobile(css`
    padding: 0 20px;
    height: 100%;
  `)}
`

export const SearchBox = styled(GridContainer)`
  max-width: 1160px;

  form {
    display: flex;
    flex-direction: row;
    position: relative;
    top: 13px;
    width: 100%;
    z-index: 1;
  }

  .open {
    animation: bounce-in 300ms ease-in;
  }

  .close {
    animation: bounce-out 300ms ease-in;
  }

  @keyframes bounce-in {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  @keyframes bounce-out {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(0.5);
    }
  }

  input {
    background: #f7ee6f;
    color: #7d7d7d;
    text-transform: capitalize;
    text-align: center;
    display: inline-block;
    outline: none;
    padding: 12px 30px 12px 16px;
    border-radius: 20px;
    border: 1px ${colors.lightGrey} solid;
    width: 100%;
    font-size: 30px;
    box-shadow: 0px 2px 3px 0px rgb(0 0 0 / 22%);
  }

  ${mobile(css`
    padding: 0 20px;
    height: 100%;
  `)}
`

export const Logo = styled.div`
  display: inline-block;
  width: 190px;
  margin-top: 25px;

  img {
    max-width: 100%;
  }

  ${mobile(css`
    max-width: 120px;
  `)}
`

export const MenuWrapper = styled.ul`
  display: inline-block;
  list-style-type: none;
  margin: 26px 30px 0 120px;
  padding: 0;
  flex-grow: 1;
  text-align: right;

  li {
    display: inline-block;
    margin: 0;
    margin-right: 45px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  a {
    color: ${colors.contrast};
    text-decoration: none;
    font-size: ${rem(17)};
    font-weight: ${fontWeights.medium};

    position: relative;
    padding: 0.3em 0;

    &.is-active {
      color: ${colors.contrast};
    }
  }

  a::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.2em;
    background-color: ${colors.contrast};
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
  }

  a:hover::after,
  a:focus::after {
    opacity: 1;
    transform: translate3d(0, 0.2em, 0);
  }

  ${mobile(css`
    margin: 0;
    text-align: right;
    flex-grow: 1;
    align-self: center;

    li {
      margin-right: 20px;
      margin-bottom: 4px;
    }
  `)}
`

export const SearchWrapper = styled.div`
  display: inline-block;
  padding-top: 12px;

  form {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 70px;
  }

  input {
    display: inline-block;
    outline: none;
    padding: 12px 30px 12px 16px;
    border-radius: 20px;
    border: 1px ${colors.lightGrey} solid;
    width: 320px;
  }

  button {
    background-color: transparent;
    border: none;
    color: ${colors.contrast};
    transform: translate3d(0, -50%, 0);
    font-size: 18px;
    cursor: pointer;
  }

  .search-close-icon {
    position: relative;
    top: 24px;
  }

  ${mobile(css`
    input {
      width: auto;
    }
  `)}
`

import React, { ReactElement } from 'react'
import { Helmet } from 'react-helmet'

import { Container, Wrapper } from './styles'
import { BuilderPageProps } from '@/types/pages/builder'
import ExperienceBuilder from '@/components/builder/Builder'

/**
 * Builder page
 */
export default function Builder({
  pageContext,
}: BuilderPageProps): ReactElement {
  const { page } = pageContext
  return (
    <Wrapper>
      <Helmet>
        <title>{page.title}</title>
      </Helmet>
      <Container>
        <ExperienceBuilder page={page} />
      </Container>
    </Wrapper>
  )
}

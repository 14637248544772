import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Container as GridContainer } from '../../styles/grid'
import { mobile, rem } from '../../styles/functions'
import { colors, fontWeights } from '../../styles/variables'

export const Wrapper = styled.section`
  background-color: ${colors.lightGrey};
  color: ${colors.grey};

  ${mobile(css`
    /* height: 60px; */
  `)}
`

export const Container = styled(GridContainer)`
  ${mobile(css`
    padding: 0 20px;
    height: 100%;
  `)}
`

export const Bottom = styled.div`
  background-color: ${colors.primary};

  > div {
    justify-content: flex-end;
  }
`

export const Logo = styled.div`
  display: inline-block;
  width: 250px;
  margin-top: 20px;
  margin-bottom: 20px;

  img {
    max-width: 100%;
  }

  ${mobile(css`
    max-width: 150px;
  `)}
`

export const MenuWrapper = styled.ul`
  display: inline-block;
  list-style-type: none;
  margin: 30px 0 0 120px;
  padding: 0;
  flex-grow: 1;
  text-align: right;

  li {
    display: inline-block;
    margin: 0;
    margin-left: 24px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  a {
    color: ${colors.text};
    text-decoration: none;
    font-size: ${rem(16)};
    font-weight: ${fontWeights.medium};
    display: inline-block;
    padding: 8px 20px;
    border-radius: 20px;

    &.is-active {
      color: ${colors.contrast};
      background-color: ${colors.primary};
    }
  }

  ${mobile(css`
    margin: 0;
    text-align: right;
    flex-grow: 1;
    align-self: center;
    display: none;

    li {
      margin-right: 20px;
      margin-bottom: 4px;
    }
  `)}
`

export const SearchWrapper = styled.div`
  display: inline-block;
  padding-top: 20px;
  padding-bottom: 20px;

  form {
    display: flex;
    flex-direction: row;
    position: relative;
  }

  input {
    display: inline-block;
    outline: none;
    padding: 12px 70px 12px 16px;
    border-radius: 6px;
    border: 1px ${colors.lightGrey} solid;
    width: 320px;
  }

  button {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: transparent;
    border: none;
    color: ${colors.contrast};
    font-size: 18px;
    background-color: ${colors.green};
    border-radius: 0 6px 6px 0;
    padding: 0 16px;
  }

  ${mobile(css`
    flex-grow: 1;

    input {
      width: 100%;
    }
  `)}
`

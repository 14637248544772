import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Container as GridContainer } from '../../styles/grid'
import { mobile, rem } from '../../styles/functions'
import { colors, fontWeights } from '../../styles/variables'

export const Wrapper = styled.section`
  padding-bottom: 30px;

  ${mobile(css`
    padding-bottom: 20px;
  `)}
`

export const Container = styled(GridContainer)`
  flex-direction: column;
  border-top: 2px ${colors.lightGrey} solid;
  max-width: none;
  padding-top: 30px;
`

export const MenuWrapper = styled.ul`
  display: block;
  text-align: center;
  list-style-type: none;
  margin-bottom: 20px;
  padding: 0;

  li {
    display: inline-block;
    margin: 0 22px;
  }

  a {
    color: ${colors.text};
    text-decoration: none;
    text-transform: uppercase;
    font-size: ${rem(13)};
    font-weight: ${fontWeights.medium};
  }

  ${mobile(css`
    margin-top: 32px;
  `)}
`

export const Bottom = styled(GridContainer)`
  color: ${colors.mediumGrey};
  width: 100%;

  > div {
    display: block;
    flex-grow: 1;
  }
`

export const Logo = styled.div`
  display: inline-block;
  width: 132px;
  float: left;

  img {
    max-width: 100%;
  }

  ${mobile(css`
    max-width: 120px;
  `)}
`

export const Copyright = styled.div`
  display: inline-block;
  font-size: ${rem(14)};
  font-weight: ${fontWeights.medium};
  color: inherit;
  float: right;
`

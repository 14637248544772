import { Colors, Fonts, FontSizes, FontWeights } from './types'

/**
 * Colors
 */
export const colors: Colors = {
  contrast: `#ffffff`,
  green: `#11b137`,
  grey: `#424242`,
  lightGrey: `#efefef`,
  mediumGrey: `#b4b4b4`,
  primary: `#235ea0`,
  secondary: `#be0d33`,
  text: `#333333`,
}

export const fonts: Fonts = {
  primary: `'Work Sans', sans-serif`,
}

export const fontSizes: FontSizes = {
  root: 16,
  h1: 40,
}

export const fontWeights: FontWeights = {
  medium: 500,
  regular: 400,
}

import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Global } from '@emotion/react'
import isFunction from 'lodash/isFunction'

import Gallery from './Gallery'
import InfiniteScroll from './InfiniteScroll'
import Quiz from './Quiz'
import { ArticleContent, Wrapper, buttons } from './styles'
import wait from '@/utils/wait'
import { Article } from '@/types/models/article'

interface Props {
  article: Article
  children: ReactNode
  contentId?: number
  layout?: string
}

interface AdMethods {
  refresh: () => () => void
  properBuildSlots: () => void
  properInfNewPage: () => void
  properSpaNewPage: () => void
}

export interface ContentProps {
  article: Article
  contentId: number
  methods: AdMethods
  layout?: string
}

// Refresh ads every 45 seconds
const refreshInterval = 45 * 1000

/**
 * Contents
 */
export default function Contents({
  article,
  children,
  contentId = 1,
  layout,
}: Props): ReactElement {
  const [isLayout, setLayout] = useState<boolean>(false)
  const methods = useMemo<AdMethods>(() => {
    const waitAndExecute = (method: string) => {
      const execute = async () => {
        while (!isFunction(window[method])) {
          await wait(50)
        }
        window[method]()
      }
      execute()
    }
    return {
      refresh: () => {
        const interval = setInterval(() => {
          // Disable calling this every few seconds
          waitAndExecute(`properSpaNewPage`)
        }, refreshInterval)
        return () => {
          clearInterval(interval)
        }
      },
      properBuildSlots: () => waitAndExecute(`properBuildSlots`),
      properInfNewPage: () => waitAndExecute(`properInfNewPage`),
      properSpaNewPage: () => waitAndExecute(`properSpaNewPage`),
    }
  }, [])

  useEffect(() => {
    if (layout === `v2` && article.type !== `POST`) {
      setLayout(true)
    } else {
      setLayout(false)
    }
  })
  return (
    <>
      <Global styles={buttons} />
      <Wrapper className={isLayout ? `layout2` : ``}>
        <ArticleContent>
          {(() => {
            switch (article.type) {
              case `GALLERY`:
                return (
                  <Gallery
                    article={article}
                    contentId={contentId}
                    methods={methods}
                    layout={layout}
                  />
                )
              case `INFINITE SCROLL`:
                return (
                  <InfiniteScroll
                    article={article}
                    contentId={contentId}
                    methods={methods}
                  />
                )
              case `QUIZZES`:
                return (
                  <Quiz
                    article={article}
                    contentId={contentId}
                    methods={methods}
                  />
                )
              default:
                return <>{children}</>
            }
          })()}
        </ArticleContent>
      </Wrapper>
    </>
  )
}

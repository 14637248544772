import React, { ReactElement } from 'react'

import { Container, Title, Wrapper } from './styles'

/**
 * NotFound page
 */

export default function NotFound(): ReactElement {
  return (
    <Wrapper>
      <Container>
        <Title>404: Page not found</Title>
      </Container>
    </Wrapper>
  )
}

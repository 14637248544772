import React, { ReactElement } from 'react'
import { useQueryParams, StringParam } from 'use-query-params'

import { Wrapper, AdItem, AdHead } from './styles'
import { YahooAdsProps } from './types'
import useClient from '@/utils/hooks/use-client'

export default function SearchYahooAds({
  yahooads,
  onClick,
}: YahooAdsProps): ReactElement {
  const [{ s }] = useQueryParams({
    s: StringParam,
  })
  const isClient = useClient()
  const handleClickParent = (id, taScore) => {
    onClick(id, taScore)
  }

  return (
    <Wrapper>
      <AdHead>Sponsored Listings For &quot;{s}&quot;</AdHead>
      {isClient && !yahooads.length ? (
        <div>No ad(s) found</div>
      ) : (
        yahooads.map(
          ({
            id,
            url,
            title,
            description,
            clickUrl,
            impressionId,
            related,
            taScore,
          }) => (
            <AdItem key={`${id}`} data-yiid={impressionId}>
              <div style={{ width: `100%` }}>
                <div className="left-block">
                  <a
                    onClick={() => handleClickParent(id, taScore)}
                    className="ad-yahoo"
                    title={title}
                    target="_blank"
                    rel="noreferrer"
                    href={clickUrl}
                  >
                    <div
                      className="ad-title"
                      dangerouslySetInnerHTML={{ __html: title }}
                    />
                  </a>
                  <a
                    className="ad-link"
                    href={clickUrl}
                    target="_blank"
                    rel="noreferrer"
                    style={{ cursor: `pointer`, display: `block` }}
                    onClick={() => handleClickParent(id, taScore)}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: url.toLowerCase() }}
                    />
                  </a>
                  <a
                    className="ad-description"
                    href={clickUrl}
                    target="_blank"
                    rel="noreferrer"
                    style={{ cursor: `pointer`, display: `block` }}
                    onClick={() => handleClickParent(id, taScore)}
                  >
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                  </a>
                </div>
                <div className="right-block">
                  {!!related.length &&
                    related.map((v) => (
                      <div key={v} className="label">
                        {v}
                      </div>
                    ))}
                </div>
              </div>
            </AdItem>
          ),
        )
      )}
    </Wrapper>
  )
}

import getClient from './client'

export default async function checkBlackList(referrerUrl) {
  if (referrerUrl === ``) return false
  if (referrerUrl === undefined) return false

  const { data } = await getClient().get(`cms/check-blacklist`, {
    params: {
      referrer: referrerUrl,
    },
  })

  const result = data || false

  return result
}

import kebabCase from 'lodash/kebabCase'
import React, { ReactElement } from 'react'
import { Link } from 'gatsby'

import { Container, Wrapper } from './styles'
import { BreadcrumbsProps } from './types'

/**
 * Breadcrumbs component
 */
export default function Breadcrumbs({
  hide = false,
  links,
}: BreadcrumbsProps): ReactElement {
  if (hide) {
    return <></>
  }
  return (
    <Wrapper>
      <Container>
        <ul>
          {links.map((link) => (
            <li key={kebabCase(`${link.title} ${link.url}`)}>
              <Link to={link.url}>{link.title}</Link>
            </li>
          ))}
        </ul>
      </Container>
    </Wrapper>
  )
}

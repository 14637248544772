import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Container as GridContainer } from '../../styles/grid'
import { mobile } from '../../styles/functions'

export const Container = styled(GridContainer)`
  flex-direction: column;
  max-width: 970px;
  padding-top: 50px;
  position: relative;

  ${mobile(css`
    padding: 20px;
  `)}

  .search-logo {
    position: absolute;
    left: -200px;
    top: 58px;
    width: 175px;
  }

  .search-big {
    align-items: center;
    cursor: text;
    display: flex;
    flex: 1 1 100%;
    font-size: 14px;
    padding: 10px;
    border: 1px solid transparent;
    box-shadow: 0 2px 5px 1px rgba(64, 60, 67, 0.16);
    border-radius: 24px;

    .fa {
      font-weight: 900;
      font-size: 21px;
    }

    .fa-search {
      display: flex;
      color: #ff1b9b;
      margin: 0px 8px;
    }

    input {
      text-indent: 16px;
      height: 30px;
      border: 0px;
      width: 100%;
      font-size: 1rem;
      font-weight: 400;
    }

    input:focus {
      outline: none;
    }

    button {
      border: none;
      background: transparent;
      cursor: pointer;
    }
  }

  .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;

    .form-control {
      position: relative;
      flex: 1 1 auto;
      min-width: 0;
      margin-bottom: 0;
      display: block;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
    }

    .btn {
      display: inline-block;
      font-weight: 400;
      text-align: center;
      vertical-align: middle;
      user-select: none;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      line-height: 1.5;
      border-radius: 0.25rem;

      .fa {
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
      }
    }

    .btn-primary {
      color: #fff;
      background-color: #007bff;
      border-color: #007bff;
    }
  }
`

export const Wrapper = styled.div`
  display: block;
  padding: 20px 0;
  flex-grow: 1;
`

export const Loader = styled.div`
  display: block;
  margin: 10px;
`

export const AdContent = styled.div`
  p {
    padding: 10px;
    margin: 10px 0px;
  }
`

export const Item = styled.div`
  display: block;
  border-radius: 8px;
  font-size: 20px;
  overflow: hidden;
  margin: 0px 0px 20px 0px;
  padding: 10px;

  &:hover {
    background-color: #73c1ef30;
  }

  a {
    display: inline-block;
    width: 100%;
    color: inherit;
    text-decoration: none;
    box-sizing: border-box;
    position: relative;
    transition: 0.2s background-color ease;

    &:after {
      content: '›';
      position: absolute;
      right: 20px;
      top: 24px;
      font-size: 48px;
      padding: 8px 14px 16px 14px;
      border: 3px rgba(221, 56, 116, 1) solid;
      border-radius: 8px;
      line-height: 20px;
      color: rgba(221, 56, 116, 1);
      display: none;
    }

    .ad-title {
      font-size: 28px;
      font-weight: 500;
      margin-bottom: 12px;
    }
  }

  .ad-description {
    font-size: 16px;
    font-weight: normal;
    padding-top: 5px;
    cursor: pointer;
    font-family: arial, sans-serif;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  a .ad-title {
    color: #1a0dab;
    font-family: arial, sans-serif;
    font-size: 20px;
    line-height: 1.3;
    margin-bottom: 5px;
  }

  a .ad-link {
    color: #202124;
    font-style: normal;
    font-size: 15px;
    line-height: 1.3;
    margin-bottom: 8px;
  }

  a:after {
    content: none !important;
  }
`

import React, { ReactElement, useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { useLocation } from '@reach/router'
import isArray from 'lodash/isArray'
import trim from 'lodash/trim'

import { Quiz as QuizType } from '@/types/models/article'
import Content from './Content'
import { ContentProps } from './Contents'
import {
  Ads,
  AdSection,
  Container,
  ConvoBubble,
  Main,
  Title,
  TopBlock,
} from './styles'
import IconCheck from '@/assets/images/icon-check.svg'
import IconWrong from '@/assets/images/icon-close.svg'

interface LocationState {
  corrects?: number
}

type Step = 'intro' | 'question' | 'correct' | 'wrong'

/**
 * Quiz content type
 */
export default function Quiz({
  article,
  contentId,
  methods,
}: ContentProps): ReactElement {
  const location = useLocation()
  const state = location.state as LocationState
  const [step, setStep] = useState<Step>(contentId > 1 ? `question` : `intro`)
  const [corrects, setCorrects] = useState<number>(state?.corrects || 0)
  const contents = (article.articles as QuizType[]) || []
  const quiz = contents[contentId - 1]
  const lastQuiz = contents.length === contentId

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: `smooth`,
    })
  }

  const topBlock = () => {
    switch (step) {
      case `intro`:
        return (
          <>
            <Title dangerouslySetInnerHTML={{ __html: article.title }} />
            <Content html={article.content} />
          </>
        )
      case `question`:
        return (
          <>
            <Title dangerouslySetInnerHTML={{ __html: quiz.title }} />
            <Ads className="getinfo-leaderboard" />
          </>
        )
      default:
        return (
          <>
            <Ads className="getinfo-leaderboard" />
          </>
        )
    }
  }

  const resultMessage = (isCorrect: boolean): string => {
    if (isCorrect) {
      return `NAILED IT!${lastQuiz ? ` WELL DONE!` : ` KEEP GOING!`}`
    }
    return `NOT QUITE!${lastQuiz ? `` : ` TRY ANOTHER!`}`
  }

  const contentBlock = () => {
    const choices = isArray(quiz.choices)
      ? (quiz.choices as string[])
      : quiz.choices.split(`,`).map((choice) => trim(choice))
    const isCorrect = step === `correct`
    switch (step) {
      case `intro`:
        return (
          <>
            <AdSection>
              <div className="getinfo-left-1" />
              <div className="getinfo-left-2" />
            </AdSection>
            <button
              className="green-arrow-button"
              type="button"
              onClick={() => {
                setStep(`question`)
                scrollTop()
              }}
            >
              START QUIZ &gt;&gt;
            </button>
          </>
        )
      case `question`:
        return (
          <>
            <AdSection>
              <div className="getinfo-left-1" />
              <div className="getinfo-left-2" />
            </AdSection>
            {choices.map((choice) => (
              <button
                className="green-button"
                type="button"
                key={choice}
                onClick={() => {
                  const isCorrectAnswer = quiz.answer === choice
                  setCorrects((prev) => prev + (isCorrectAnswer ? 1 : 0))
                  setStep(isCorrectAnswer ? `correct` : `wrong`)
                }}
              >
                {choice}
              </button>
            ))}
          </>
        )
      case `correct`:
      case `wrong`:
        return (
          <>
            <ConvoBubble>
              <div>
                SCORE: {((corrects / contents.length) * 100).toFixed(0)}%
              </div>
              <div className={step}>
                <span>
                  <img
                    src={isCorrect ? IconCheck : IconWrong}
                    alt="check icon"
                  />
                </span>
                {resultMessage(isCorrect)}
              </div>
              <div>Correct Answer: {quiz.answer}</div>
            </ConvoBubble>
            <div>
              <p style={{ textAlign: `center` }}>
                <strong
                  dangerouslySetInnerHTML={{ __html: quiz.answer_description }}
                />
              </p>
            </div>
            <AdSection>
              <div className="getinfo-left-1" />
              <div className="getinfo-left-2" />
            </AdSection>
            <button
              className="green-arrow-button"
              type="button"
              onClick={() => {
                navigate(
                  `/article/${article.slug}/${
                    lastQuiz ? `` : `${contentId + 1}/`
                  }`,
                  {
                    state: {
                      corrects: 0,
                    },
                  },
                )
              }}
            >
              NEXT QUESTION &gt;&gt;
            </button>
          </>
        )
      default:
        return <></>
    }
  }

  useEffect(() => {
    methods.properSpaNewPage()
  }, [methods, step])

  useEffect(() => {
    if (contentId <= 1) {
      setCorrects(0)
    }
  }, [contentId])

  useEffect(() => methods.refresh(), [methods])

  if (!quiz) {
    return <></>
  }
  return (
    <Main>
      <Container>
        <TopBlock>{topBlock()}</TopBlock>
      </Container>
      <Container>
        <div />
        <div>
          {!!quiz.image && <img src={quiz.image} alt="" />}
          <AdSection>
            <Ads className="getinfo-mobile" />
          </AdSection>
          {contentBlock()}
          <AdSection>
            <Ads className="getinfo-mobile" />
          </AdSection>
        </div>
        <div />
      </Container>
    </Main>
  )
}

import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { mobile } from '../../styles/functions'

export const Wrapper = styled.section``

export const Container = styled.div`
  display: block;
  margin: 0 auto;
  max-width: 1300px;
  padding: 0 20px;
  margin-top: 4rem;

  .post-name {
    margin-top: 4rem;
    font-weight: 600;
    font-size: 30px;
    line-height: 1.4;
    margin: 5px 0;
    color: #3c3c3c;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .hp-left {
    grid-column-start: 1;
  }

  .hp-right {
  }

  ${mobile(css`
    padding: 0 12px;
    margin-top: 2rem;

    .hp-block {
      grid-template-columns: 1fr;
      grid-gap: 0px;
    }

    .hp-right {
      grid-column-start: 1;
      grid-row: 4;
    }
  `)}
`

export const AdSection = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
`

export const Loader = styled.div`
  display: block;
  margin: 20px 0 10px 0;
`

export const ArticleSection = styled.div`
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;

  .hp-block {
    display: grid;
    grid-template-columns: 60% auto;
    grid-gap: 150px;
    color: #3c3c3c;
    align-items: start;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 40px;
  }

  .hp-block > div {
    width: 100%;
  }

  .hp-right {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-top: 50px;
  }

  ${mobile(css`
    .hp-block {
      grid-template-columns: 1fr;
      grid-gap: 15px;
      border-bottom: none;
      padding-bottom: 0;
    }
  `)}
`

import React, { ReactElement } from 'react'
import { Helmet } from 'react-helmet'

import { TaboolaProps } from './types'

/**
 * Taboola Pixel component
 */
export default function Pixel({ children }: TaboolaProps): ReactElement {
  const inmobiPixel = `
    window._tfa = window._tfa || [];
    window._tfa.push({notify: 'event', name: 'page_view', id: 1571832});
    !function (t, f, a, x) {
            if (!document.getElementById(x)) {
              t.async = 1;t.src = a;t.id=x;f.parentNode.insertBefore(t, f);
            }
    }(document.createElement('script'),
    document.getElementsByTagName('script')[0],
    '//cdn.taboola.com/libtrc/unip/1571832/tfa.js',
    'tb_tfa_script2');
  `

  const typPixel = `
  window._tfa = window._tfa || [];
  window._tfa.push({notify: 'event', name: 'page_view', id: 1604191});
  !function (t, f, a, x) {
         if (!document.getElementById(x)) {
            t.async = 1;t.src = a;t.id=x;f.parentNode.insertBefore(t, f);
         }
  }(document.createElement('script'),
  document.getElementsByTagName('script')[0],
  '//cdn.taboola.com/libtrc/unip/1604191/tfa.js',
  'tb_tfa_script3');
  `
  return (
    <>
      <Helmet>
        <script>
          {`
            window._tfa = window._tfa || [];
            window._tfa.push({notify: 'event', name: 'page_view', id: 1703983});
            !function (t, f, a, x) {
                   if (!document.getElementById(x)) {
                      t.async = 1;t.src = a;t.id=x;f.parentNode.insertBefore(t, f);
                   }
            }(document.createElement('script'),
            document.getElementsByTagName('script')[0],
            '//cdn.taboola.com/libtrc/unip/1703983/tfa.js',
            'tb_tfa_script');
            ${inmobiPixel}
            ${typPixel}
            `}
        </script>
      </Helmet>
      {children}
    </>
  )
}

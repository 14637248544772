export function shortenText(text: string, charLimit: number) {
  // Check if the text is already within the limit
  if (text.length <= charLimit) {
    return text
  }

  // Initialize variables to track the end of the sentence
  let endIndex = charLimit

  // Look for the last period within the char limit
  for (let i = charLimit - 1; i >= 0; i -= 1) {
    if (text[i] === `.`) {
      endIndex = i + 1
      break
    }
  }

  // Return the shortened text and whether the text can continue
  return text.slice(0, endIndex).trim()
}

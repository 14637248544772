import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { mobile } from '../../styles/functions'
import { Container as GridContainer } from '../../styles/grid'

export const Wrapper = styled.section``

export const Container = styled(GridContainer)`
  ${mobile(css`
    padding: 20px;
  `)}
`

import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Container as GridContainer } from '../../styles/grid'
import { mobile, rem } from '../../styles/functions'
import { colors, fontSizes, fontWeights } from '../../styles/variables'

export const Wrapper = styled.section``

export const Container = styled(GridContainer)`
  flex-direction: column;
  max-width: 1300px;
  margin: 0 auto;
  padding: 40px 20px 0 20px;

  ${mobile(css`
    padding: 20px;
  `)}
`

export const Title = styled.h1`
  font-size: ${rem(fontSizes.h1)};
  font-weight: ${fontWeights.medium};
  margin: 0 0 24px 0;
  color: #666;
  font: 700 25px/140% open_sans, arial, sans-serif;

  ${mobile(css`
    font-size: ${rem(32)};
    margin-bottom: 20px;
  `)}
`

export const Content = styled.div`
  line-height: ${rem(30)};
  margin-bottom: 20px;
  text-align: center;

  .content {
    text-align: left;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: ${fontWeights.medium};
  }

  h4 {
    font-size: ${rem(20)};
  }

  blockquote {
    font-size: ${rem(18)};
    font-style: italic;
    border-left: 1px ${colors.grey} solid;
    margin: 0;
    padding-left: 20px;
  }

  ${mobile(css`
    margin-bottom: 20px;
  `)}
`

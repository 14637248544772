import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { mobile } from '../../styles/functions'
import { Container as GridContainer, PageWrapper } from '../../styles/grid'
import { colors } from '../../styles/variables'

export const Wrapper = styled(PageWrapper)``

export const Container = styled(GridContainer)`
  flex-direction: column;

  ${mobile(css`
    padding: 20px;
  `)}
`

export const SearchInput = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 50px;

  input {
    flex-grow: 1;
    display: inline-block;
    outline: none;
    padding: 12px 30px 12px 16px;
    border-radius: 4px;
    border: 1px ${colors.primary} solid;
    font-size: 24px;
  }

  div {
    font-style: italic;
    padding-top: 10px;
  }
`

import React, { ReactElement } from 'react'
import { Link } from 'gatsby'

import sortBy from 'lodash/sortBy'
import kebabCase from 'lodash/kebabCase'
import { Container, Wrapper } from './styles'
import { useContext } from '@/components/app/context'
import { LinkInterface } from '@/types/common'
import getMenuUrl from '@/utils/routes/get-menu-url'

export default function Footer(): ReactElement {
  const [state] = useContext()

  const links: LinkInterface[] = sortBy(
    (state.menu || []).filter((item) => !!item.is_bottom),
    `sort`,
  ).map((item) => ({
    key: String(item.id),
    title: item.title,
    url: getMenuUrl(item),
  }))

  return (
    <Wrapper>
      <Container>
        <div className="footer-logo">
          <Link to="/">
            {!!state.siteSettings?.logo && (
              <img src={state.siteSettings.logo} alt="Site logo" />
            )}
          </Link>
        </div>
        <div className="nav-menu">
          <button type="button">
            <i className="fa fa-bars" />
          </button>
        </div>
        <div className="footer-menu">
          {links.map((link) => (
            <Link
              key={kebabCase(`${link.title} ${link.url} ${link.key}`)}
              to={link.url}
            >
              {link.title}
            </Link>
          ))}
        </div>
      </Container>
    </Wrapper>
  )
}
